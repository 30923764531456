import { Component, Input } from '@angular/core';
import { ClassicParagraph } from 'src/app/interfaces/components.interface';

@Component({
	selector: 'app-classic-paragraphs',
	templateUrl: './classic-paragraphs.component.html',
	styleUrls: ['./classic-paragraphs.component.scss'],
})
export class ClassicParagraphsComponent {
	@Input() datas!: ClassicParagraph[];
}
