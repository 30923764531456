import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
	selector: 'app-events-actions',
	templateUrl: './events-actions.component.html',
	styleUrls: ['./events-actions.component.scss'],
})
export class EventsActionsComponent implements OnInit {
	constructor(public eventService: EventService) {}

	ngOnInit(): void {}
}
