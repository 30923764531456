import { Component, OnInit } from '@angular/core';
import { HeroSecondary } from 'src/app/interfaces/components.interface';
import { TitleWithSubtitle } from 'src/app/interfaces/title-with-subtitle.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';
import { Press } from '../../interfaces/collections.interface';

@Component({
	selector: 'app-press',
	templateUrl: './press.page.html',
	styleUrls: ['./press.page.scss'],
})
export class PressPage implements OnInit {
	heroSecondary!: HeroSecondary;
	titleSection!: TitleWithSubtitle;

	resources!: Press[];
	categories: any[] = [];

	constructor(private componentsService: ComponentsService, private collectionService: CollectionsService) {}

	ngOnInit(): void {
		this.getAllPress();
		this.getDatas();
	}

	async getAllPress() {
		this.resources = await this.collectionService.getAllPress();
		const uniqueTypeResource = Array.from(new Set(this.resources.map((a) => a.type))).map((type) => {
			return this.resources.find((a) => a.type === type);
		});
		uniqueTypeResource.forEach((resource) => {
			this.categories = [...this.categories, resource?.type];
		});
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'PressPage',
			'error to fetch home datas',
		);
		const pressPageDatas = response.pressPage.length > 0 && response.pressPage[0].children;
		if (pressPageDatas) {
			this.heroSecondary = this.componentsService.formatHeroSecondary(pressPageDatas);
			this.titleSection = this.componentsService.formatTitleSubtitleSection(pressPageDatas);
		}
	}
}
