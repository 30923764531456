<section class="faq-details" *ngIf="questions">
	<div class="fd-container">
		<app-section-title
			[title]="'Des questions sur l\'action ?'"
			[subtitle]="'Retrouvez les questions les plus fréquentes sur la Semaine du numérique et des sciences informatiques'"></app-section-title>
		<div class="fdc-wrapper">
			<!-- Accordion Start -->
			<accordion [collapsing]="true">
				<accordion-item *ngFor="let question of questions" [title]="question.question">
					<ng-template accordionContent>
						<div class="p-4">
							{{ question.answer }}
						</div>
					</ng-template>
				</accordion-item>
			</accordion>
			<!-- Accordion End -->
		</div>
	</div>
</section>
