import { Component, Input, OnInit } from '@angular/core';
import { IntroduceSection } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-introduce-nsi',
	templateUrl: './introduce-nsi.component.html',
	styleUrls: ['./introduce-nsi.component.scss'],
})
export class IntroduceNsiComponent implements OnInit {
	@Input() datas!: IntroduceSection;
	imagesUrl: string = environment.imagesURL;
	constructor() {}

	ngOnInit(): void {}
}
