<section class="hero-events" *ngIf="datas">
	<div class="he-background-image" [ngStyle]="{ 'background-image': 'url(' + pathImageApi + datas.image + ')' }"></div>
	<div class="he-container">
		<h1 class="he-title">{{ datas.title }}</h1>
		<form class="hec-form-container" [formGroup]="heroForm">
			<div class="hec-title-search-wrapper">
				<h2 class="hec-title">Rechercher un événement</h2>
				<label for="search" class="screen-reader-text">Rechercher un événement</label>
				<input
					id="search"
					class="hec-input"
					type="text"
					placeholder="Rechercher"
					formControlName="description"
					list="items" />
				<datalist id="items">
					<option *ngFor="let event of filteredEvents" [value]="event.name">{{ event.name }}</option>
				</datalist>
			</div>
			<div class="hec-filters-wrapper">
				<div class="hecfw-item">
					<label class="hecfwi-label" for="region">Région</label>
					<select id="region" name="region" class="hecfwi-input" formControlName="region" (change)="updateRegion()" >
						<option *ngFor="let region of regionList" value="region" [value]="region">
							{{ removeAccents(region) }}
						</option>
					</select>
				</div>
				<div class="hecfw-item">
					<label class="hecfwi-label" for="department">Département</label>
					<select id="department" name="department" class="hecfwi-input" formControlName="department" >
						<option *ngFor="let dept of departmentList" value="dept" [value]="dept">
							{{ removeAccents(dept) }}
						</option>
					</select>
				</div>
				<div class="hecfw-item">
					<label class="hecfwi-label" for="city">Ville</label>
					<input class="hecfwi-input" type="text" id="city" placeholder="Ville" formControlName="city" autocomplete="off"/>
				</div>
			</div>
			<custom-button
				class="hecfw-button"
				color="accent"
				level="primary"
				size="small"
				iconAfter="search-outline"
				[callBackFunctionOnClick]="onSubmit"
				>Rechercher</custom-button
			>
		</form>

		<custom-button
				class="fnecf-reset"
				color="primary"
				level="secondary"
				iconBefore="close-outline"
				[callBackFunctionOnClick]="resetSearch"
				type="button"
				size="small"
				*ngIf="eventService.isListFiltered"
				>réinitialiser la recherche</custom-button>

	</div>
</section>
