import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Promoter } from 'src/app/interfaces/collections.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-promoters-slider',
	templateUrl: './promoters-slider.component.html',
	styleUrls: ['./promoters-slider.component.scss'],
})
export class PromotersSliderComponent implements OnInit {
	@Input() promoters!: Promoter[];
	pathImageApi = environment.imagesURL;

	allPromoters!: any[];
	customOptions: OwlOptions = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		autoWidth: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		navSpeed: 200,
		navText: ['', ''],
		slideBy: '3',
		responsive: {
			0: {
				items: 1,
				slideBy: '1',
			},
			800: {
				items: 2,
				slideBy: '2',
			},
			1200: {
				items: 3,
				slideBy: '3',
			},
		},
		nav: true,
	};

	constructor() {}

	ngOnInit(): void {}
}
