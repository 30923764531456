<section class="case-list classic-section" *ngIf="datas">
	<div class="cl-container classic-container">
		<div class="clc-text-wrapper">
			<app-section-title [title]="datas.title" [subtitle]="datas.subtitle"></app-section-title>
			<ul class="clc-list">
				<li class="clcl-item" *ngFor="let item of datas.list">{{ item }}</li>
			</ul>
			<div class="clc-button-wrapper" *ngIf="datas.addButton">
				<p class="clcbw-description">{{ datas.buttonField.name }}</p>
				<custom-button
					routerLink="/{{datas.buttonField.button.link}}"
					[color]="datas.buttonField.button.color"
					[level]="datas.buttonField.button.level"
					size="small"
					iconAfter="arrow-ios-forward-outline"
					>{{ datas.buttonField.button.text }}</custom-button
				>
			</div>
		</div>
		<div class="clc-round-wrapper">
			<div class="clcrw-round">
				<div class="clcrwr-image-mob" [ngStyle]="{ 'background-image': 'url(' + imagesUrl + datas.image + ')' }"></div>
			</div>
		</div>
		<img src="{{ imagesUrl }}{{ datas.image }}" class="clc-image" />
	</div>
</section>
