import { Component, OnInit } from '@angular/core';
import { AboutDetails, ClassicParagraph, ClassicStickiesButton, HeroTernary, ListCases } from 'src/app/interfaces/components.interface';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
	selector: 'app-how-to-contribute',
	templateUrl: './how-to-contribute.page.html',
	styleUrls: ['./how-to-contribute.page.scss'],
})
export class HowToContributePage implements OnInit {
	heroTernary!: HeroTernary;
	stickiesButtons!: ClassicStickiesButton[];
	aboutDetails!: AboutDetails;
	paragraphFirstPart!: ClassicParagraph[];
	paragraphSecondPart!: ClassicParagraph[];
	listCases!: ListCases;

	constructor(private componentsService: ComponentsService) { }

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'HowToContribute',
			'error to fetch HowToContribute datas',
		);
		const howToContributePageDatas =
			response.howToContributePage.length > 0 && response.howToContributePage[0].children;
		if (howToContributePageDatas) {
			this.heroTernary = this.componentsService.formatHeroTernary(howToContributePageDatas);
			this.stickiesButtons = this.componentsService.formatStickyButtonsBox(howToContributePageDatas);
			this.aboutDetails = this.componentsService.formatAboutDetails(howToContributePageDatas);
			this.paragraphFirstPart = this.componentsService.formatClassicParagraphsSection(howToContributePageDatas, 0);
			this.listCases = this.componentsService.formatListCasesSection(howToContributePageDatas);
			this.paragraphSecondPart = this.componentsService.formatClassicParagraphsSection(howToContributePageDatas, 1);
		}
	}
}
