import { Component, Input, OnInit } from '@angular/core';
import { Edition } from 'src/app/interfaces/collections.interface';
import { PreviousEditions } from 'src/app/interfaces/components.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-previous-editions',
  templateUrl: './previous-editions.component.html',
  styleUrls: ['./previous-editions.component.scss']
})
export class PreviousEditionsComponent implements OnInit {
  @Input() datas!: PreviousEditions;
  editions!: Edition[];
  imageUrl = environment.imagesURL;

  constructor(private collectionService: CollectionsService, private componentsService: ComponentsService) { }

  ngOnInit(): void {
    this.getAllPreviousEditions();
  }

  async getAllPreviousEditions() {
    const response: Edition[] = await this.collectionService.getAllPreviousEditions();
    this.editions = response;
  }

  redirectToLink(link: string) {
    window.location.href = link;
  }

}
