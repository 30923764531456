import { Component, Input, OnInit } from '@angular/core';
import { Partner } from 'src/app/interfaces/collections.interface';
import { OurPartners } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
	selector: 'app-our-partners',
	templateUrl: './our-partners.component.html',
	styleUrls: ['./our-partners.component.scss'],
})
export class OurPartnersComponent implements OnInit {
	@Input() isHomePage = false;
	@Input() datas!: OurPartners;
	partners!: Partner[];
	pathImageApi = environment.imagesURL;
	constructor(private collectionService: CollectionsService) {}

	ngOnInit(): void {
		this.getAllPartners();
	}

	async getAllPartners() {
		const response: Partner[] = await this.collectionService.getAllPartners();
		this.partners = response;
	}
}
