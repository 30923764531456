import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Event } from 'src/app/interfaces/event.interface';
import { WeekDatesStartEnd } from '../interfaces/collections.interface';
import { CollectionsService } from './collections.service';
import { nextEvents } from '../data/fake-events.data';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	allEventsFromApi: Event[] = [];
	allEventsFromApiSubject = new Subject<Event[]>();
	emitAllEventsFromApiSubject() {
		this.allEventsFromApiSubject.next(this.allEventsFromApi);
	}

	eventDisplayed: Event[] = [];
	eventDisplayedSubject = new Subject<Event[]>();
	emitEventDisplayedSubject() {
		this.eventDisplayedSubject.next(this.eventDisplayed);
	}

	eventFilterDescription: string = "";
	eventFilterDescriptionSubject = new Subject<string>();
	emitEventFilterDescriptionSubject() {
		this.eventFilterDescriptionSubject.next(this.eventFilterDescription);
	}

	eventFilterRegion: string = "";
	eventFilterRegionSubject = new Subject<string>();
	emitEventFilterRegionSubject() {
		this.eventFilterRegionSubject.next(this.eventFilterRegion);
	}

	eventFilterDepartment: string = "";
	eventFilterDepartmentSubject = new Subject<string>();
	emitEventFilterDepartmentSubject() {
		this.eventFilterDepartmentSubject.next(this.eventFilterDepartment);
	}

	eventFilterCity: string = "";
	eventFilterCitySubject = new Subject<string>();
	emitEventFilterCitySubject() {
		this.eventFilterCitySubject.next(this.eventFilterCity);
	}

	isListFiltered = false;

	eventsDisplayedSliced: Event[] = [];
	eventsDisplayedSlicedSubject = new Subject<Event[]>();
	emiteventsDisplayedSlicedSubject() {
		this.eventsDisplayedSlicedSubject.next(this.eventsDisplayedSliced);
	}

	indexSliceMoreEvent = 6;

	isAddMoreButtonDisplayed = true;
	isAddMoreButtonDisplayedSubject = new Subject<boolean>();
	emitIsAddMoreButtonDisplayedSubject() {
		this.isAddMoreButtonDisplayedSubject.next(this.isAddMoreButtonDisplayed);
	}

	targetedPublic: string[] = [];
	targetedPublicSubject = new Subject<string[]>();
	emitTargetedPublicSubject() {
		this.targetedPublicSubject.next(this.targetedPublic);
	}

	eventTypes: string[] = [];
	eventTypesSubject = new Subject<string[]>();
	emitEventTypesPublicSubject() {
		this.eventTypesSubject.next(this.eventTypes);
	}

	constructor(private httpClient: HttpClient, private collectionsService: CollectionsService) {}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.log(error);
			console.log(`${operation} failed : ${error.message}`);
			return of(result as T);
		};
	}

	weekDatesStartEnd!: WeekDatesStartEnd;
	startDate!: Date;
	endDate!: Date;

	eventDates: Date[] = [];
	eventDatesSubject = new Subject<Date[]>();

	getWeekDatesStartEnd = async () => {
		this.weekDatesStartEnd = await this.collectionsService.getNsiWeekDates();
		this.startDate = this.weekDatesStartEnd.startDate.date;
		this.endDate = this.weekDatesStartEnd.endDate.date;
	};

	emitEventDatesPublicSubject() {
		this.eventDatesSubject.next(this.eventDates);
	}

	async getAllEventsFromAPI() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events`).toPromise();
		this.allEventsFromApi = response;
		this.emitAllEventsFromApiSubject();
		this.eventDisplayed = response;
		this.resetIndex();
		this.addMoreButtonWatcher();
		this.emitEventDisplayedSubject();
		this.getAllTypes();
		this.getAllPublics();
		this.getWeekDatesStartEnd();
		if (this.startDate && this.endDate) this.getDatesBetween(this.startDate, this.endDate);
	}

	async getAllEventsFromAPIFromYear(year: string) {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events`).toPromise();
		const filteredByYearResponse = response.filter((event: any) => {
			const eventYear = new Date(event.start_date).getFullYear().toString();
			return eventYear == year;
		});

		this.allEventsFromApi = filteredByYearResponse;
		this.emitAllEventsFromApiSubject();
		this.eventDisplayed = filteredByYearResponse;
		this.resetIndex();
		this.addMoreButtonWatcher();
		this.emitEventDisplayedSubject();
		this.getAllTypes();
		this.getAllPublics();
		this.getWeekDatesStartEnd();
		if (this.startDate && this.endDate) this.getDatesBetween(this.startDate, this.endDate);
	}

	updateEventDisplayed(events: Event[]) {
		this.isListFiltered = true;
		this.eventDisplayed = events;
		this.resetIndex();
		this.addMoreButtonWatcher();
		this.emitEventDisplayedSubject();
		this.emitEventFilterDescriptionSubject();
		this.emitEventFilterRegionSubject();
		this.emitEventFilterDepartmentSubject();
		this.emitEventFilterCitySubject();
	}

	resetEventDisplayed() {
		this.isListFiltered = false;
		this.eventDisplayed = this.allEventsFromApi;
		this.resetIndex();
		this.addMoreButtonWatcher();
		this.emitEventDisplayedSubject();
	}

	async getNextEvents(): Promise<Event[]> {
		// if (!this.allEventsFromApi || this.allEventsFromApi.length < 1) {
		await this.getAllEventsFromAPI();
		let sortedEventByDate = [...this.allEventsFromApi].sort((a: Event, b: Event) => {
			const dateA = new Date(a.start_date).getTime();
			const dateB = new Date(b.start_date).getTime();
			return dateA - dateB;
		});
		sortedEventByDate = this.removePastDates(sortedEventByDate);
		return sortedEventByDate.slice(0, 3);
		// }
	}

	removePastDates(events: Event[]): Event[] {
		let currentDate = new Date();
		let nextEvents = [];
		for (let e of events) {
			if (e.end_date && new Date(e.start_date).getTime() >= currentDate.getTime()) nextEvents.push(e);
		}
		return nextEvents;
	}

	async getEventById(eventId: string) {
		if (
			this.allEventsFromApi &&
			this.allEventsFromApi.length > 0 &&
			this.allEventsFromApi.find((event) => event.id === eventId)
		) {
			return this.allEventsFromApi.find((event) => event.id === eventId);
		} else {
			const response: any = await this.httpClient.get(`${environment.apiUrl}/events/${eventId}`).toPromise();
			return response;
		}
	}

	async getAllDatesFromDisplayedEvents(): Promise<Date[]> {
		const dates: Date[] = [];
		this.eventDisplayed.forEach((event) => {
			const eventDate = new Date(event.start_date);

			const dateExists = dates.some(
				(date: Date) =>
					date.getDate() === eventDate.getDate() &&
					date.getMonth() === eventDate.getMonth() &&
					date.getFullYear() === eventDate.getFullYear(),
			);

			if (!dateExists) {
				dates.push(eventDate);
			}
		});

		return dates.sort((a: any, b: any) => {
			return new Date(a).getTime() - new Date(b).getTime();
		});
	}

	resetIndex() {
		this.indexSliceMoreEvent = 6;
		this.eventsDisplayedSliced = this.eventDisplayed.slice(0, this.indexSliceMoreEvent);
		this.addMoreButtonWatcher();
		this.emiteventsDisplayedSlicedSubject();
	}

	addMoreEvents() {
		this.indexSliceMoreEvent += 3;
		this.eventsDisplayedSliced = this.eventDisplayed.slice(0, this.indexSliceMoreEvent);
		this.addMoreButtonWatcher();
		this.emiteventsDisplayedSlicedSubject();
	}

	addMoreButtonWatcher() {
		this.isAddMoreButtonDisplayed = this.indexSliceMoreEvent <= this.eventDisplayed.length ? true : false;
		this.emitIsAddMoreButtonDisplayedSubject();
	}

	getAllTypes() {
		this.eventTypes = [];
		const uniqueType = Array.from(new Set(this.eventDisplayed.map((a) => a.type))).map((typeEvent) => {
			return this.eventDisplayed.find((a) => a.type === typeEvent);
		});
		uniqueType.forEach((elt) => {
			if (elt && elt.public) {
				this.eventTypes = [...this.eventTypes, elt?.type];
			}
		});
		this.emitEventTypesPublicSubject();
	}

	getAllPublics() {
		this.targetedPublic = [];
		let targetedPublicTemp: string[] = [];

		this.eventDisplayed.forEach((elt) => {
			if (elt && elt.public.length > 0) {
				targetedPublicTemp = [...targetedPublicTemp, ...elt.public];
			}
		});
		this.targetedPublic = Array.from(new Set(targetedPublicTemp));
		this.emitTargetedPublicSubject();
	}

	getDatesBetween(sDate: Date, eDate: Date) {
		this.eventDates = [];
		let cDate: Date = sDate;
		while (formatDate(cDate, 'dd-MM-yyyy', 'en-US') <= formatDate(eDate, 'dd-MM-yyyy', 'en-US')) {
			this.eventDates.push(new Date(cDate));
			cDate = new Date(cDate);
			cDate.setDate(cDate.getDate() + 1);
		}
		this.emitEventDatesPublicSubject();
	}

	filterAllEventsByFirstLevelFilters() {

		this.eventDisplayed = this.allEventsFromApi;

		if (this.eventFilterDescription != '') {
			this.updateEventDisplayed(
				this.allEventsFromApi.filter((elt) => elt.name.toLowerCase().includes(this.eventFilterDescription.toLowerCase())),
			);
		}
		if (this.eventFilterRegion != '') {
			this.updateEventDisplayed(this.allEventsFromApi.filter((elt) => elt.region == this.eventFilterRegion));
		}
		if (this.eventFilterDepartment != '') {
			this.updateEventDisplayed(
				this.allEventsFromApi.filter((elt) => elt.department == this.eventFilterDepartment),
			);
		}
		if (this.eventFilterCity != '') {
			this.updateEventDisplayed(
				this.allEventsFromApi.filter((elt) => elt.city.trim().toLowerCase() == this.eventFilterCity.trim().toLowerCase()),
			);
		}	
	}

	filterByTargetsOrByTypes(chosenPublic: string, chosenType: string, chosenDate: Date) {
		
		this.filterAllEventsByFirstLevelFilters();
		
		if (chosenPublic != '') {
			this.updateEventDisplayed(this.eventDisplayed.filter((elt) => elt.public.includes(chosenPublic)));
		}

		if (chosenType != '') {
			this.updateEventDisplayed(this.eventDisplayed.filter((elt) => elt.type === chosenType));
		}

		if (chosenDate) {
			this.updateEventDisplayed(
				this.eventDisplayed.filter((elt) => {
					return (
						formatDate(new Date(chosenDate), 'dd-MM-yyyy', 'en-US') ===
						formatDate(new Date(elt.start_date), 'dd-MM-yyyy', 'en-US')
					);
				})
			);
		}
	}
}