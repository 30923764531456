<article class="hero" *ngIf="datas">
	<section class="h-section">
		<div class="hs-text-container">
			<p class="hstc-subtitle">Du {{ eventDatesStringifyed }}</p>
			<h1 class="hstc-title">{{ datas?.title }}</h1>
			<p class="hstc-paragraph">{{ datas?.text }}</p>

			<!-- REMOVE EVENT -->
			<div *ngIf="isDepositeOpen" class="hstc-button-wrapper">
				<a href="https://app.semaine-nsi.fr/signin">
					<custom-button color="secondary" level="primary" size="small" iconAfter="arrow-ios-forward-outline">Déposer un événement</custom-button>
				</a>
				<custom-button routerLink="/evenements" color="secondary" level="ghost" size="small" iconAfter="arrow-ios-forward-outline" *ngIf="eventService.allEventsFromApi && eventService.allEventsFromApi.length > 0">Voir tous les événements</custom-button>
			</div>
			<div class="hstc-button-wrapper" *ngElse>
				<custom-button routerLink="/comment-participer" color="secondary" level="primary" size="small" iconAfter="arrow-ios-forward-outline">Comment participer</custom-button>
				<custom-button routerLink="/evenements-2022" color="secondary" level="ghost" size="small" iconAfter="arrow-ios-forward-outline">Voir l'édition 2022</custom-button>
			</div>
		</div>
		<div class="hs-images-container">
			<div class="hsic-image-1-wrapper">
				<div class="hsiciw-image image-1"></div>
			</div>
			<div class="hsici-image-2-wrapper">
				<div class="hsici-image image-2"></div>
			</div>
		</div>
	</section>
</article>