import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { StateAlert } from 'src/app/classes/state-alert';

@Component({
	selector: 'app-state-alert',
	templateUrl: './state-alert.particle.html',
	styleUrls: ['./state-alert.particle.scss'],
})
export class StateAlertParticle implements OnInit {
	@Input() test!: string;
	@Input() stateAlert!: StateAlert;
	isHidden = true;
	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['stateAlert'] && this.stateAlert) {
			this.isHidden = false;
			setTimeout(() => (this.isHidden = true), this.stateAlert.duration);
		}
	}
}
