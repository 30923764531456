import { Component, Input, OnInit } from '@angular/core';
import { CollectionsService } from 'src/app/services/collections.service';

interface FormatDate {
	month: number;
	day: number;
	hour: number;
	minutes: number;
}

@Component({
	selector: 'app-countdown',
	templateUrl: './countdown.component.html',
	styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
	today = new Date();
	NSIDate: Date = new Date('2023-12-04T09:00');
	distance!: number;

	daysLeft!: number;
	hoursLeft!: number;
	minutesLeft!: number;
	secondsLeft!: number;

	constructor(private collectionsService: CollectionsService) {}

	ngOnInit(): void {
		this.getWeekDatesStartEnd();
		this.getDayLeft();
	}

	getWeekDatesStartEnd = async () => {
		const weekDatesStartEnd = await this.collectionsService.getNsiWeekDates();
		this.NSIDate = new Date(`${weekDatesStartEnd.startDate.date} ${weekDatesStartEnd.startDate.time}`);
	};

	getDayLeft() {
		const x = setInterval(() => {
			this.distance = new Date(this.NSIDate).getTime() - new Date().getTime();

			this.daysLeft = Math.floor(this.distance / (1000 * 60 * 60 * 24));
			this.hoursLeft = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			this.minutesLeft = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
			this.secondsLeft = Math.floor((this.distance % (1000 * 60)) / 1000);

			if (this.distance < 0) {
				clearInterval(x);
			}
		}, 1000);
	}
}
