import { Component, Input, OnInit } from '@angular/core';
import { AboutDetails } from 'src/app/interfaces/components.interface';

@Component({
	selector: 'app-about-details',
	templateUrl: './about-details.component.html',
	styleUrls: ['./about-details.component.scss'],
})
export class AboutDetailsComponent implements OnInit {
	@Input() datas!: AboutDetails;

	constructor() {}

	ngOnInit(): void {}
}
