import { Component, Input } from '@angular/core';
import { SubForm } from 'src/app/interfaces/components.interface';

@Component({
	selector: 'app-sub-form',
	templateUrl: './sub-form.component.html',
	styleUrls: ['./sub-form.component.scss'],
})
export class SubFormComponent {
	@Input() datas!: SubForm;

	constructor() {}
}
