import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StateAlert, StateAlertBuilder } from 'src/app/classes/state-alert';
import { allDepartments } from 'src/app/data/departments.data';
import { WichForm } from 'src/app/enums/event-form.enum';
import { EventFormSection } from 'src/app/interfaces/components.interface';
import { MailsService } from 'src/app/services/mails.service';
import { environment } from 'src/environments/environment';
import { PublicType } from 'src/app/enums/public-type.enum';

@Component({
	selector: 'app-event-form',
	templateUrl: './event-form.component.html',
	styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
	@Input() datas!: EventFormSection;
	@Input() wichForm?: WichForm = WichForm.CONTRIBUTE;

	regionList: string[] = [];
	allDepartmentList = allDepartments;

	contributeForm!: FormGroup;
	participateForm!: FormGroup;
	eventId!: string | null;

	submitted = false;
	submitting = false;
	success = false;
	submitError = false;

	phonePattern = '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$';

	publicTypes = Object.values(PublicType).filter((value) => typeof value === 'string');

	stateAlert!: StateAlert;

	constructor(
		private formBuilder: FormBuilder,
		private mailService: MailsService,
		private activatedRoute: ActivatedRoute,
		private httpClient: HttpClient,
	) { }

	getAllRegionFromDepartmentList = () => {
		this.regionList = [];

		this.allDepartmentList.forEach((dept) => {
			if (this.regionList.indexOf(dept.region_name) === -1) {
				this.regionList.push(dept.region_name);
			}
		});

		this.regionList.sort();
	};

	ngOnInit(): void {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
		this.getAllRegionFromDepartmentList();
		this.initForm();
	}

	isContributeForm(): boolean {
		return this.wichForm === WichForm.CONTRIBUTE ? true : false;
	}

	initForm() {
		if (this.isContributeForm()) {
			this.contributeForm = this.formBuilder.group({
				firstname: ['', Validators.required],
				lastname: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				phone: ['', Validators.pattern(this.phonePattern)],
				selectedRegion: [''],
				interventionZone: [''],
				organization: [''],
				userFunction: [''],
				disponibilities: this.formBuilder.array([]),
				description: [''],
				isOnline: [''],
				isRGPD: ['', Validators.requiredTrue]
			});
		}
		if (!this.isContributeForm()) {
			this.participateForm = this.formBuilder.group({
				firstname: ['', Validators.required],
				lastname: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				phone: ['', Validators.pattern(this.phonePattern)],
				city: ['', Validators.required],
				zipcode: ['', Validators.required],
				organization: [''],
				userFunction: [''],
				nbrOfParticipants: [1, [Validators.required, Validators.min(1)]],
				public: [''],
				informations: [''],
				isRGPD: ['', Validators.requiredTrue]
			});
		}
	}

	handleIncrement() {
		this.participateForm.patchValue({ nbrOfParticipants: this.nbrOfParticipants?.value + 1 });
	}
	handleDecrement() {
		if (this.nbrOfParticipants?.value > 1)
			this.participateForm.patchValue({ nbrOfParticipants: this.nbrOfParticipants?.value - 1 });
	}

	onAddDisponibility() {
		const newDisponibilityControl = this.formBuilder.control(null, Validators.required);
		this.disponibilities.push(newDisponibilityControl);
	}

	onSubmitForm() {
		const formValue = this.isContributeForm() ? this.contributeForm.value : this.participateForm.value;
		if (this.isContributeForm()) {
			this.mailService.sendContributeEventMail({ form: formValue }).subscribe(
				() => (this.stateAlert = new StateAlertBuilder('Le formulaire a bien été envoyé').buildSuccess()),
				() =>
				(this.stateAlert = new StateAlertBuilder(
					"Une erreur s'est produite dans l'envoi de votre formulaire...",
				).buildError()),
			);
		}
		if (!this.isContributeForm() && this.eventId) {
			this.httpClient.post(`${environment.apiUrl}/events/${this.eventId}/participate`, { form: formValue }).subscribe(
				() =>
				(this.stateAlert = new StateAlertBuilder(
					"Votre inscription a bien été envoyée. Nous laissons le soin à l'organisation référente de vous contacter directement si besoin.",
				).buildSuccess()),
				() =>
				(this.stateAlert = new StateAlertBuilder(
					"Une erreur s'est produite dans l'envoi de votre formulaire...",
				).buildError()),
			);
		}
		window.scrollTo(0, 300);
		if (this.isContributeForm()) this.removeAllDisponibilities();
		this.isContributeForm() ? this.contributeForm.reset() : this.participateForm.reset();
	}

	getDisponibilities() {
		return this.contributeForm.get('disponibilities') as FormArray;
	}

	removeAllDisponibilities() {
		const disponibilitiesArray = this.contributeForm.get('disponibilities') as FormArray;
		while (disponibilitiesArray.length) {
			disponibilitiesArray.removeAt(0);
		}
	}

	get firstname() {
		if (this.isContributeForm()) return this.contributeForm.get('firstname');
		return this.participateForm.get('firstname');
	}
	get lastname() {
		if (this.isContributeForm()) return this.contributeForm.get('lastname');
		return this.participateForm.get('lastname');
	}
	get email() {
		if (this.isContributeForm()) return this.contributeForm.get('email');
		return this.participateForm.get('email');
	}
	get phone() {
		if (this.isContributeForm()) return this.contributeForm.get('phone');
		return this.participateForm.get('phone');
	}
	get selectedRegion() {
		return this.contributeForm.get('selectedRegion');
	}
	get city() {
		if (this.isContributeForm()) return this.contributeForm.get('city');
		return this.participateForm.get('city');
	}
	get zipcode() {
		if (this.isContributeForm()) return this.contributeForm.get('zipcode');
		return this.participateForm.get('zipcode');
	}
	get interventionZone() {
		if (this.isContributeForm()) return this.contributeForm.get('interventionZone');
		return this.participateForm.get('interventionZone');
	}
	get organization() {
		if (this.isContributeForm()) return this.contributeForm.get('organization');
		return this.participateForm.get('organization');
	}
	get userFunction() {
		if (this.isContributeForm()) return this.contributeForm.get('userFunction');
		return this.participateForm.get('userFunction');
	}
	get disponibilities(): FormArray {
		return this.contributeForm.get('disponibilities') as FormArray;
	}
	get description() {
		return this.contributeForm.get('description');
	}
	get isOnline() {
		return this.contributeForm.get('isOnline');
	}
	get nbrOfParticipants() {
		return this.participateForm.get('nbrOfParticipants');
	}
	get public() {
		return this.participateForm.get('public');
	}
	get informations() {
		return this.participateForm.get('informations');
	}
}
