import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EventProfile, EventProfileBuilder } from 'src/app/classes/event-profile-card';
import { EventStates } from 'src/app/enums/event-state.enum';
import { Event } from 'src/app/interfaces/event.interface';

@Component({
	selector: 'app-event-profile-card',
	templateUrl: './event-profile-card.component.html',
	styleUrls: ['./event-profile-card.component.scss'],
})
export class EventProfileCardComponent implements OnInit {
	@Input() event!: Event;
	eventProfile!: EventProfile;
	state!: string;
	constructor() {}

	onChanges(changes: SimpleChanges) {}

	ngOnInit(): void {
		if (this.event) this.instanciateTheRightEventState();
		if (this.event && this.event.eventDates)
			this.event.eventDates = this.event.eventDates.sort(function (a: any, b: any) {
				return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
			});
	}

	instanciateTheRightEventState() {
		const eventProfileBuilder = new EventProfileBuilder(
			this.event.city,
			this.event.registration_conditions,
			this.event.organization_type,
			this.event.start_date,
			this.event.end_date,
		);
		if (this.isLastEdition()) {
			this.state = 'passed';
			this.eventProfile = eventProfileBuilder.buildLastEdition();
		} else if (this.isEventInProgress()) {
			this.state = 'in-progress';
			this.eventProfile = eventProfileBuilder.buildInProgress();
		} else if (this.isEventPassed()) {
			this.state = 'passed';
			this.eventProfile = eventProfileBuilder.buildFinished();
		} else if (this.event.complete) {
			this.state = 'complete';
			this.eventProfile = eventProfileBuilder.buildComplete();
		} else {
			this.state = 'open';
			this.eventProfile = eventProfileBuilder.buildOpen();
		}
	}

	isEventPassed(): boolean {
		if (new Date(this.event.start_date) > new Date()) return false;
		return true;
	}

	isEventInProgress(): boolean {
		if (new Date(this.event.start_date) < new Date() && new Date(this.event.end_date) > new Date()) return true;
		return false;
	}

	isLastEdition(): boolean {
		if (new Date(this.event.start_date).getFullYear() < new Date().getFullYear()) return true;
		return false;
	}

	eventLeftTickets(): number {
		if (this.event.max_capacity && this.event.attendees) {
			return this.event.max_capacity - this.event.attendees;
		} else if (this.event.max_capacity) {
			return this.event.max_capacity;
		} else {
			return 0;
		}
	}
}
