import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WeekDatesStartEnd } from 'src/app/interfaces/collections.interface';
import { HeroPrimary } from 'src/app/interfaces/components.interface';
import { VariablesGlobales } from 'src/app/interfaces/global-variables.interface';
import { NewsLetterForm } from 'src/app/interfaces/newsletter-form.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { EventService } from 'src/app/services/event.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { formatWeekDatesStartEnd } from 'src/app/utils/dates.utils';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-hero',
	templateUrl: './hero.component.html',
	styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {
	@Input() datas!: HeroPrimary;

	isDepositeOpen: boolean = this.globalVar.isDepositeOpen;

	weekDatesStartEnd!: WeekDatesStartEnd;
	eventDatesStringifyed!: string;

	newsletterForm!: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private newsletterService: NewsletterService,
		public eventService: EventService,
		private collectionsService: CollectionsService,
		private globalVar: VariablesGlobales
	) { }

	ngOnInit(): void {
		this.getWeekDatesStartEnd();
		this.collectionsService.getNsiWeekDates();
		this.newsletterForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	ngAfterViewChecked() {
		if (this.datas?.bigImage) {
			let headerStyle: any = document.querySelector('.hero');
			headerStyle.style.setProperty('--image-1', `url('${environment.imagesURL + this.datas?.bigImage}')`);
		}

		if (this.datas?.smallImage) {
			let headerStyle: any = document.querySelector('.hero');
			headerStyle.style.setProperty('--image-2', `url('${environment.imagesURL + this.datas?.smallImage}')`);
		}
	}

	getWeekDatesStartEnd = async () => {
		this.weekDatesStartEnd = await this.collectionsService.getNsiWeekDates();
		this.eventDatesStringifyed = formatWeekDatesStartEnd(
			this.weekDatesStartEnd.startDate,
			this.weekDatesStartEnd.endDate,
		);
	};
}
