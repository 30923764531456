import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
	selector: 'app-event-details',
	templateUrl: './event-details.page.html',
	styleUrls: ['./event-details.page.scss'],
})
export class EventDetailsPage implements OnInit {
	event!: any;
	currentDate!: Date;
	nextEventsDatas: {
		title: string;
		subtitle: string;
	};

	constructor(private route: ActivatedRoute, private eventService: EventService) {
		this.nextEventsDatas = {
			title: 'Nos autres événements',
			subtitle: 'Retrouvez les autres événements disponibles sur la plateforme.',
		};
	}

	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => this.getEventDetails(params['id'] || ''));
		//this.getEventDetails(this.route.snapshot.paramMap.get('id') || '');
	}

	async getEventDetails(id: string) {
		this.event = await this.eventService.getEventById(id);
		this.event.description = this.event.description.replace(/\n/g, '<br />');
		this.event.schedules.sort((a: any, b: any) => {
			return Number(new Date(a.start)) - Number(new Date(b.start));
		});
		this.currentDate = new Date();
	}

	getFullTime(input: string): string {
		const date = new Date(input);
		return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}h${
			date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
		}`;
	}

	getYear(input: string): string {
		const date = new Date(input);
		return `${date.getFullYear()}`;
	}

	inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
}
