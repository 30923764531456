<header class="header" [ngClass]="isNavSticky ? 'sticky-nav' : ' '" onscroll="stickyNav()">
	<div class="h-container">
		<a routerLink="/">
			<img class="hc-logo" src="./assets/logos/nsi-min.svg" alt="logo NSI" />
		</a>
		<!-- UNCOMMENT FOR NEXT STEP -->
		<nav class="hc-nav">
			<ul class="hcn-list">
				<li class="hcnl-item" *ngIf="eventService.allEventsFromApi && eventService.allEventsFromApi.length > 0" routerLinkActive="active">
					<a routerLink="/evenements" class="hcnli-link"> Nos événements </a>
				</li>
				<li class="hcnl-item" *ngFor="let item of navBarItems" routerLinkActive="active">
					<a [routerLink]="item.link" class="hcnli-link">
						{{ item.text | uppercase }}
					</a>
				</li>
			</ul>
		</nav>

		<div class="hc-user-connection-container">
			<a href="https://app.semaine-nsi.fr/signin"><custom-button color="primary" level="ghost" size="small">connexion</custom-button></a>
			<a *ngIf="isDepositeOpen" href="https://app.semaine-nsi.fr/signup"><custom-button color="primary" level="primary" size="small">inscription</custom-button></a>
		</div>
	</div>
</header>

<header class="header-container-mobile">
	<img class="hcm-logo" src="./assets/logos/nsi-min.svg" routerLink="/" alt="logo NSI" />
	<a [attr.aria-label]="isNavMobileOpen ? 'Close the menu' : 'Open the menu'">
		<div class="hcm-burger-wrapper" (click)="toggleNavMobile()" [ngClass]="isNavMobileOpen ? 'cross' : ''">
			<span class="hcmbw-line"></span>
		</div>
	</a>
</header>
<nav class="nav-container-mobile" [ngClass]="isNavMobileOpen ? 'open' : ''" [attr.aria-hidden]="isNavMobileOpen ? 'false' : 'true'">
	<ul class="ncm-list">
		<li class="ncml-item" (click)="closeNavMobile()" *ngFor="let item of navBarItems" [routerLink]="item.link" routerLinkActive="active">
			{{ item.text | uppercase }}
		</li>
		<a href="https://app.semaine-nsi.fr/signin">
			<li class="ncml-item" (click)="closeNavMobile()">Connexion</li>
		</a>
		<a *ngIf="isDepositeOpen" href="https://app.semaine-nsi.fr/signup">
			<li class="ncml-item" (click)="closeNavMobile()">Inscription</li>
		</a>
	</ul>
</nav>