<div class="classic-section sponsor-cta" *ngIf="datas">
    <div class="classic-container sc-container">
        <div class="sc-illu">
            <img src="../../../../assets/svg/newsletter.svg" alt="" class="sci-img">
        </div>
        <div class="sc-text">
            <h2 class="sct-title">{{datas.title}}</h2>
            <p class="sct-desc">{{datas.description}}</p>
        </div>
        <div class="sc-action">
            <custom-button *ngFor="let button of datas.buttons" routerLink="/{{button.link}}" type="button" color="secondary" level="primary" size="small" iconAfter="email">{{ button.text }}</custom-button>
        </div>
    </div>
</div>