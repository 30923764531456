import { Component, OnInit } from '@angular/core';
import { AllEventsSection, HeroEvents } from 'src/app/interfaces/components.interface';
import { ComponentsService } from 'src/app/services/components.service';
import { EventService } from 'src/app/services/event.service';

@Component({
	selector: 'app-events',
	templateUrl: './events.page.html',
	styleUrls: ['./events.page.scss'],
})
export class EventsPage implements OnInit {
	heroEvents!: HeroEvents;
	allEventsSection!: AllEventsSection;
	constructor(private eventService: EventService, private componentsService: ComponentsService) {
		this.eventService.getAllEventsFromAPIFromYear("2023");
	}

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'EventsPage',
			'error to fetch home datas',
		);
		const eventsPageDatas = response.eventsPage.length > 0 && response.eventsPage[0].children;
		if (eventsPageDatas) {
			this.heroEvents = this.componentsService.formatHeroEvents(eventsPageDatas);
			this.allEventsSection = this.componentsService.formatAllEventsSection(eventsPageDatas);
		}
	}
}
