import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent implements OnInit {
  @Input() title: string = "Lorem Ipsum";
  @Input() subtitle: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et quam elementum.";

  constructor() { }

  ngOnInit(): void {
  }

}
