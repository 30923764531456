<section class="classic-paragraphs classic-section" *ngIf="datas">
	<div class="cp-container classic-container">
		<div class="cpc-paragraph-wrapper" *ngFor="let paragraph of datas">
			<h3 class="cpcpw-title">{{ paragraph.title }}</h3>
			<p class="cpcpw-text">{{ paragraph.text }}</p>
			<custom-button
				routerLink="/{{paragraph.button?.link}}"
				*ngIf="paragraph.addButton"
				[color]="paragraph.button?.color"
				[level]="paragraph.button?.level"
				size="small"
				iconAfter="arrow-ios-forward-outline"
				>{{ paragraph.button?.text }}</custom-button
			>
		</div>
	</div>
</section>
