import { Pipe, PipeTransform } from '@angular/core';
import { Event } from 'src/app/interfaces/event.interface';

@Pipe({
	name: 'unique',
})
export class UniquePipe implements PipeTransform {
	transform(events: Event[], filter: 'description' | 'name') {
		const unique = Array.from(new Set(events.map((a) => a[filter]))).map((filt) => {
			return events.find((a: any) => a[filter] === filt);
		});
		return unique;
	}
}
