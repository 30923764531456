import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.page.html',
  styleUrls: ['./forget-password.page.scss']
})
export class ForgetPasswordPage implements OnInit {

  illuContent: any;
	email = '';
	password = '';
	error: any = {};
	submitting = false;

	constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

	ngOnInit(): void {
		this.illuContent = {
			image1: "assets/images/hero-1.jpg",
			border: "#F09E2A",
			fill: "#BA3C12" 
		};
	}

	checkErrors(): boolean {
		if (this.submitting) {
			this.error.email = !this.email || this.email.length < 3 || !/(^\w.*@\w+\.\w)/.test(this.email);
			
      return !(
				this.error.email
			);
		} else {
			return false;
		}
	}

	postForm = () => {
		this.submitting = true;

		if (this.checkErrors()) {
			const resetPasswordForm = {
        email: this.email,
			};

			// this.httpClient
			// 	.post(`${environment.apiUrl}/auth/login`, contactFormResponse)
			// 	.subscribe((data) => {
      //     console.log(data)
      //   });
		}
	};

  returnToLogin = () => {
    this.router.navigate(['/connexion'])
  }
}