<main class="main">
	<app-hero-secondary [datas]="heroSecondary"></app-hero-secondary>
	<section class="press-links">
		<div class="pl-container">
			<app-section-title [title]="titleSection.title" [subtitle]="titleSection.subtitle"></app-section-title>
			<div class="plc-wrapper">
				<app-link-card
					*ngFor="let cat of categories"
					[title]="cat"
					[elements]="resources | filter: cat"></app-link-card>
			</div>
		</div>
	</section>
</main>
