import { Component, Input, OnInit } from '@angular/core';
import { Faq } from 'src/app/interfaces/collections.interface';

@Component({
	selector: 'app-faq-details',
	templateUrl: './faq-details.component.html',
	styleUrls: ['./faq-details.component.scss'],
})
export class FaqDetailsComponent implements OnInit {
	@Input() questions!: Faq[];

	constructor() {}

	ngOnInit(): void {}
}
