<section class="accordion">
    <div *ngFor="let item of items;index as i"
      class="accordion__item" [class.disabled]="item.disabled" [class.active]="expanded.has(i)">
      <ng-container
        [ngTemplateOutlet]="(item?.customHeader?.templateRef || defaultHeader)"
        [ngTemplateOutletContext]="{$implicit: item, index: i, toggle: getToggleState(i)}"></ng-container>
      <div class="accordion__content" [class.expanded]="expanded.has(i)" [@contentExpansion]="expanded.has(i) ? 'expanded':'collapsed'">
        <ng-container *ngTemplateOutlet="item?.content?.templateRef || null"></ng-container>
      </div>
    </div>
  </section>
  
  <ng-template #defaultHeader let-item let-index="index">
    <header class="accordion__header"
      (click)="item.disabled ? {} :toggleState(index)">
      <span class="accordion__unfold-btn">
        +
      </span>
      <span class="accordion__fold-btn">
        -
      </span>
      <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
    </header>
    <ng-template #defaultTitle>
      <p class="accordion__title">{{item?.title}}</p>
    </ng-template>
  </ng-template>
  