import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-masked-illustration',
	templateUrl: './masked-illustration.component.html',
	styleUrls: ['./masked-illustration.component.scss'],
})
export class MaskedIllustrationComponent implements OnInit {
	@Input() illuContent: any;

	constructor() {
		if (!this.illuContent) {
			this.illuContent = {
				image1: 'assets/images/hero-1.jpg',
				image2: 'assets/images/hero-2.jpg',
				border: '#F09E2A',
				fill: '#043C48',
			};
		}
	}

	ngOnInit(): void {}
}
