import { Component, OnInit, Input } from '@angular/core';
import { SponsorCTA } from 'src/app/interfaces/components.interface';

@Component({
  selector: 'app-sponsor-cta',
  templateUrl: './sponsor-cta.component.html',
  styleUrls: ['./sponsor-cta.component.scss']
})
export class SponsorCtaComponent implements OnInit {
  @Input() datas!: SponsorCTA;

  constructor() { }

  ngOnInit(): void {
  }

}
