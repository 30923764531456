<article class="our-partners" *ngIf="datas">
	<section class="op-container">
		<div class="opc-header section-header">
			<div class="opch-slash sh-slash"></div>
			<div class="opch-title-subtitle-wrapper sh-title-wrapper">
				<h3 class="opchtsw-title shtw-title">{{ datas?.title }}</h3>
				<p class="opchtsw-subtitle shtw-subtitle">{{ datas?.subtitle }}</p>
			</div>
		</div>
		<div class="opc-partners-content" nfIf="partners">
			<ng-container *ngFor="let partner of partners">
				<img
					*ngIf="(isHomePage && !partner.hiddenOnHomePage) || !isHomePage"
					class="opcpc-partner"
					[src]="pathImageApi + partner.image"
					[alt]="partner.name" />
			</ng-container>
		</div>
		<div class="opc-support">
			<span class="opcs-text">{{ datas?.textBeforeImage }}</span>
			<img class="opcs-image" [src]="pathImageApi + datas.image1" alt="republique française" />
			<img class="opcs-image" [src]="pathImageApi + datas.image2" alt="OPCO Atlas" />
		</div>
		<div class="opc-link">
			<custom-button
				routerLink="/a-propos/"
				queryParamsHandling="merge"
				fragment="partenaires"
				color="primary"
				level="primary"
				size="medium"
				iconAfter="arrow-ios-forward-outline"
				>Découvrir tous nos partenaires</custom-button
			>
		</div>
	</section>
</article>
