import { Component, Input, OnInit } from '@angular/core';
import { Promoter } from 'src/app/interfaces/collections.interface';
import { ProjectPromoters } from 'src/app/interfaces/components.interface';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
	selector: 'app-project-promoters',
	templateUrl: './project-promoters.component.html',
	styleUrls: ['./project-promoters.component.scss'],
})
export class ProjectPromotersComponent implements OnInit {
	@Input() datas!: ProjectPromoters;
	promoters!: Promoter[];
	constructor(private collectionsService: CollectionsService) {}

	ngOnInit(): void {
		this.getPromoters();
	}

	async getPromoters() {
		const response = await this.collectionsService.getAllPromoters();
		this.promoters = response;
	}
}
