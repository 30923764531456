import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Event } from 'src/app/interfaces/event.interface';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
	@Input() event!: Event;
	constructor() {}

	ngOnInit(): void {
		if (this.event.event_img[0] != '"') this.event.event_img = '"' + this.event.event_img + '"';
	}
}
