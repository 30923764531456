<article class="resource-card" *ngIf="resource">
	<div class="rc-header" [ngStyle]="{ 'background-image': 'url(' + imageUrl + resource.image + ')' }"></div>
	<div class="rc-wrapper">
		<h3 class="rcw-title">{{ resource.name }}</h3>
		<p class="rcw-type">{{ resource.type }}</p>
		<a class="rcw-btn" href="{{ resource.link }}">
			<custom-button type="button" color="primary" level="primary" size="small" iconAfter="arrow-ios-forward-outline"
				>Accéder à la ressource</custom-button
			>
		</a>
	</div>
</article>
