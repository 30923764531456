<article class="event-useful">
	<section class="eu-container">
		<div class="euc-part">
			<div class="eucp-item" *ngIf="event.start_date || event.address || event.city">
				<h3 class="eucpi-title part-title">
					<span *ngIf="event.address || event.city">Lieux</span>
					<span *ngIf="event.isOnline">En ligne</span>
				</h3>
				<p *ngIf="!event.isOnline && (event.address || event.city)" class="eucpi-paragraph">
					{{
						event.address
							? event.address + (event.zip_code ? ', ' + event.zip_code : '') + (event.city ? ', ' + event.city : '')
							: event.city
					}}
				</p>
				<p *ngIf="event.isOnline" class="eucpi-paragraph">Événement 100% en ligne</p>
				<p *ngIf="event.isHybrid" class="eucpi-paragraph">Également accessible en ligne</p>
			</div>
			<div class="eucp-item" *ngIf="event.planner_email || event.planner_phone">
				<h3 class="eucpi-title part-title">Informations organisateur</h3>
				<div class="eucpi-paragraph">
					{{ event.organization_name }}
				</div>
				<div *ngIf="event.planner_phone" class="eucpi-paragraph">
					{{ event.planner_phone }}
				</div>
				<div *ngIf="event.planner_email" class="eucpi-paragraph">
					{{ event.planner_email }}
				</div>
			</div>
			<div *ngIf="event.accessibility" class="eucp-item">
				<h3 class="eucpi-title part-title">Accessibilité</h3>
				<p class="eucpi-paragraph">
					{{ event.accessibility }}
				</p>
			</div>
			<div class="eucp-item" *ngIf="event.registration || event.online_link">
				<h3 class="eucpi-title part-title">Lien visio-conférence</h3>
				<a class="eucpi-paragraph registration" [href]="event.online_link">
					{{ event.online_link }}
				</a>
			</div>
			<div *ngIf="event.registration_conditions" class="eucp-item">
				<h3 class="eucpi-title part-title">Conditions d'inscription</h3>
				<p class="eucpi-paragraph">
					{{ event.registration_conditions }}
				</p>
			</div>
			<div class="eucp-item">
				<h3 class="eucpi-title part-title">Partager sur les réseaux sociaux</h3>
				<ul class="eu-socials-list">
					<li class="eusl-item" *ngFor="let social of socials">
						<a class="eusl-link" [href]="social.link" [attr.aria-label]="social.name" target="_blank">
							<fa-icon [icon]="['fab', social.icon]" class="social-icon"> </fa-icon>
						</a>
					</li>
				</ul>
				<!-- <p class="eucpi-paragraph">
					<a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + currentRoute" target="_blank" class="fcpsiw-link"
						><fa-icon [icon]="['fab', 'facebook']"></fa-icon
					></a>
					<a
						[href]="'https://twitter.com/intent/tweet?text=' + event.name + '&url=' + currentRoute"
						target="_blank"
						class="fcpsiw-link"
						><fa-icon [icon]="['fab', 'twitter']"></fa-icon
					></a>
				</p> -->
			</div>
		</div>
	</section>
</article>
