<main class="main">
    <div class="m-container">
        <div class="mc-illu">
            <img class="mci-image" src="./assets/svg/404.svg" alt="illustration page 404" />
        </div>
        <div class="mc-content">
            <h1 class="mcc-title">Oups ! Petit problème de connexion...</h1>
            <p class="mcc-subtitle">Erreur 404</p>
            <p class="mcc-text">Nous avons perdu le contact avec la page que vous recherchez. Revenez en lieu sûr!</p>
            <custom-button class="mcc-btn" type="button" color="secondary" level="primary" size="small" iconBefore="arrow-ios-back-outline" routerLink="/">Revenir à l'accueil</custom-button>
        </div>
    </div>
</main>