import { Component, OnInit, Input } from '@angular/core';
import { ClassicStickiesButton } from 'src/app/interfaces/components.interface';
import { VariablesGlobales } from 'src/app/interfaces/global-variables.interface';

@Component({
  selector: 'app-stickies-actions',
  templateUrl: './stickies-actions.component.html',
  styleUrls: ['./stickies-actions.component.scss']
})
export class StickiesActionsComponent implements OnInit {
  @Input() datas!: ClassicStickiesButton[];
  isDepositeOpen: boolean = this.globalVar.isDepositeOpen;

  constructor(private globalVar: VariablesGlobales) { }

  ngOnInit(): void {
  }

}
