import { Component } from '@angular/core';
import { EventService } from './services/event.service';
import { AnchorScrollerService } from 'src/app/services/anchorScroller.service';
import { Router, Scroll } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'nsi-platform';

	constructor(private eventService: EventService, private anchorScrollerService: AnchorScrollerService, private router: Router) {
		this.router.events.subscribe((event: any) => {
			if (event instanceof Scroll && event.anchor) {
				setTimeout(() => {
					this.anchorScrollerService.scroll('#' + event.anchor);
				}, 100);
			}
		});
	}

	ngOnInit(): void {
		this.getEventsFromApi();
	}

	async getEventsFromApi(): Promise<void> {
		await this.eventService.getAllEventsFromAPIFromYear("2023");
	}

	inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
}
