import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AnchorScrollerService {
	constructor(private router: Router) {}

	listen() {
		this.router.events.subscribe((val) => {
			// console.log(val);
			if (val instanceof NavigationEnd) {
				const fragmentIdx = val.urlAfterRedirects.lastIndexOf('#');
				if (fragmentIdx >= 0 && fragmentIdx < val.urlAfterRedirects.length - 1) {
					const fragment = val.urlAfterRedirects.substring(fragmentIdx + 1);
					const section = document.getElementById(fragment) as HTMLElement;
					section.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'nearest',
					});
				}
			}
		});
	}

	scroll(query: string) {
		const targetElement = document.querySelector(query);
		if (!targetElement) {
			window.scrollTo(0, 0);
		} else if (!this.isInViewport(targetElement)) {
			targetElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
	}

	private isInViewport = (elem: any) => {
		const bounding = elem.getBoundingClientRect();
		return (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	};
}
