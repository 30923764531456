import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ButtonClassic } from '../interfaces/buttons.interface';
import {
	AboutDetails,
	AllEventsSection,
	ClassicParagraph,
	ContactBottom,
	HeroEvents,
	HeroPrimary,
	HeroSecondary,
	HeroTernary,
	IntroduceSection,
	IntroduceTrophees,
	ListCases,
	NextEvents,
	NsiDivision,
	OurPartners,
	PartnersTypeData,
	PreviousEditions,
	ProjectPromoters,
	SponsorCTA,
	SubForm,
	Footer,
	Link,
	ClassicStickiesButton,
	EventFormSection,
} from '../interfaces/components.interface';

@Injectable({
	providedIn: 'root',
})
export class ComponentsService {
	private singleTonsPath = 'api/singletons/get/';

	constructor(private httpClient: HttpClient) {}

	private log(log: string) {
		// console.info(log);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.log(`${operation} failed : ${error.message}`);
			return of(result as T);
		};
	}

	getAllPages() {
		return this.httpClient
			.get(`${environment.cockpitURL}api/singletons/listSingletons?token=${environment.allSingletonsToken}`)
			.pipe(
				tap((_) => this.log('all singletons fetched')),
				catchError(this.handleError('impossible to fetch singletons', [])),
			);
	}

	getAllDatasOfSinglePage(pathAPI: string, errorMessage: string) {
		return this.httpClient
			.get(`${environment.cockpitURL}api/singletons/get/${pathAPI}`)
			.pipe(
				tap((_) => this.log(`${pathAPI} datas fetched`)),
				catchError(this.handleError(`${errorMessage}`, [])),
			)
			.toPromise();
	}

	formatFooter(datas: any) {
		const datasFetched = datas;
		const links: Link[] = [];
		datasFetched.links.forEach((link: any) => {
			links.push({
				name: link.value.name,
				path: link.value.path,
			});
		});
		return {
			logoWeek: datasFetched.logoWeek.path,
			socialTitle: datasFetched.socialTitle,
			alertsTitle: datasFetched.alertsTitle,
			alertsButton: datasFetched.alertsButton,
			links,
		};
	}

	formatHeroPrimary(datas: any[]): HeroPrimary {
		const datasFetched = datas.find((elt: any) => elt.component === 'heroPrimary').settings;
		return {
			title: datasFetched.title,
			text: datasFetched.text,
			bigImage: datasFetched.bigImage.path,
			smallImage: datasFetched.smallImage.path,
		};
	}

	formatHeroSecondary(datas: any[]): HeroSecondary {
		const datasFetched = datas.find((elt: any) => elt.component === 'heroSecondary').settings;
		return {
			title: datasFetched.title,
			image: datasFetched.image.path,
		};
	}

	formatHeroTernary(datas: any[]): HeroTernary {
		const datasFetched = datas.find((elt: any) => elt.component === 'heroTernary').settings;
		const formatedButtons: ButtonClassic[] = [];
		if (datasFetched.buttons)
			datasFetched.buttons.forEach((button: any) => {
				formatedButtons.push({
					color: button.value.color,
					link: button.value.link,
					level: button.value.level,
					text: button.value.text,
				});
			});
		return {
			title: datasFetched.title,
			image: datasFetched.image.path,
			isButtonLinks: datasFetched.isButtonLinks,
			buttons: formatedButtons,
		};
	}

	formatHeroEvents(datas: any[]): HeroEvents {
		const datasFetched = datas.find((elt: any) => elt.component === 'heroEvents').settings;
		return {
			title: datasFetched.title,
			image: datasFetched.image.path,
		};
	}

	formatIntroduceSection(datas: any[]): IntroduceSection {
		const datasFetched = datas.find((elt: any) => elt.component === 'introduceSection').settings;
		return {
			firstParagraph: datasFetched.firstParagraph,
			secondParagraph: datasFetched.secondParagraph,
			thirdParagraph: datasFetched.thirdParagraph,
			headIllus: datasFetched.headIllus,
			firstIcon: datasFetched.firstIcon,
			secondIcon: datasFetched.secondIcon,
		};
	}

	formatNsiDivision(datas: any[]): NsiDivision {
		const datasFetched = datas.find((elt: any) => elt.component === 'nsiDivision').settings;
		return {
			title: datasFetched.title,
			text: datasFetched.text,
		};
	}
	formatNextEvents(datas: any[]): NextEvents {
		const datasFetched = datas.find((elt: any) => elt.component === 'nextEvents').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
		};
	}
	formatOurPartners(datas: any[]): OurPartners {
		const datasFetched = datas.find((elt: any) => elt.component === 'ourPartners').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
			textBeforeImage: datasFetched.textBeforeImage,
			image1: datasFetched.image1.path,
			image2: datasFetched.image2.path,
		};
	}

	formatPartnersByType(datas: any[], type: string): PartnersTypeData {
		const datasFetched = datas.find(
			(elt: any) => elt.component === 'financialPartners' && elt.settings.type == type,
		).settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
			type: datasFetched.category,
		};
	}

	formatPreviousEditions(datas: any[]): PreviousEditions {
		const datasFetched = datas.find((elt: any) => elt.component === 'previousEditions').settings;
		const images: string[] = [];
		datasFetched.images.forEach((image: any) => {
			images.push(image.value.path);
		});
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
			images,
		};
	}

	formatAboutDetails(datas: any[]): AboutDetails {
		const datasFetched = datas.find((elt: any) => elt.component === 'simpleText').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
			textInner: datasFetched.text,
		};
	}

	formatIntroduceTrophees(datas: any[]): IntroduceTrophees {
		const datasFetched = datas.find((elt: any) => elt.component === 'introduceTrophees').settings;
		return {
			firstParagraph: datasFetched.firstParagraph,
			secondParagraph: datasFetched.secondParagraph,
			titleParagraph: datasFetched.titleParagraph,
		};
	}

	formatProjectPromoters(datas: any[]): ProjectPromoters {
		const datasFetched = datas.find((elt: any) => elt.component === 'projectPromoter').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
		};
	}

	formatContactBottom(datas: any[]): ContactBottom {
		const datasFetched = datas.find((elt: any) => elt.component === 'contactBottom').settings;
		let button: ButtonClassic | undefined = undefined;
		if (datasFetched.isButton) {
			button = {
				color: datasFetched.intervButton.color,
				level: datasFetched.intervButton.level,
				link: datasFetched.intervButton.link,
				text: datasFetched.intervButton.text,
			};
		}
		return {
			title: datasFetched.title,
			image: datasFetched.image.path,
			genericTitle: datasFetched.genericTitle,
			genericSubtitle: datasFetched.genericSubtitle,
			callInterv: datasFetched.callInterv,
			isButton: datasFetched.isButton,
			intervButton: button && button,
		};
	}

	formatAllEventsSection(datas: any[]): AllEventsSection {
		const datasFetched = datas.find((elt: any) => elt.component === 'eventsSection').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
		};
	}

	formatListCasesSection(datas: any[]): ListCases {
		const datasFetched = datas.find((elt: any) => elt.component === 'listCases').settings;
		const buttonObj = datasFetched.buttonField.bouton;
		const button: ButtonClassic = {
			text: buttonObj.text,
			color: buttonObj.color,
			level: buttonObj.level,
			link: buttonObj.link,
		};
		const list: string[] = [];
		datasFetched.list.forEach((item: Record<string, string>) => {
			list.push(item.value);
		});

		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
			image: datasFetched.image.path,
			addButton: datasFetched.addButton,
			buttonField: {
				name: datasFetched.buttonField.name,
				button,
			},
			list,
		};
	}

	formatClassicParagraphsSection(datas: any[], index: number) {
		const datasFetched = datas.filter((elt: any) => elt.component === 'paragraphs')[index].settings;
		const paragraphs: ClassicParagraph[] = [];
		datasFetched.paragraphs.forEach((paragraph: any) => {
			const button: ButtonClassic = {
				text: paragraph.value.bouton.text,
				color: paragraph.value.bouton.color,
				level: paragraph.value.bouton.level,
				link: paragraph.value.bouton.link,
			};
			paragraphs.push({
				title: paragraph.value.title,
				text: paragraph.value.text,
				addButton: paragraph.value.addButton,
				button,
			});
		});
		return paragraphs;
	}

	formatLegals(datas: any[], index: number) {
		const datasFetched = datas.filter((elt: any) => elt.component === 'paragraphs')[index].settings;
		const paragraphs: ClassicParagraph[] = [];
		datasFetched.paragraphs.forEach((paragraph: any) => {
			const button: ButtonClassic = {
				text: paragraph.value.bouton.text,
				color: paragraph.value.bouton.color,
				level: paragraph.value.bouton.level,
				link: paragraph.value.bouton.link,
			};
			paragraphs.push({
				title: paragraph.value.title,
				text: paragraph.value.text,
				addButton: paragraph.value.addButton,
				button,
			});
		});
		return paragraphs;
	}

	formatSponsorCTA(datas: any[]): SponsorCTA {
		const datasFetched = datas.find((elt: any) => elt.component === 'sponsorCTA').settings;
		const buttons: ButtonClassic[] = [];
		datasFetched.buttonField.forEach((button: any) => {
			const buttonTmp: ButtonClassic = {
				text: button.value.text,
				color: button.value.color,
				level: button.value.level,
				link: button.value.link,
			};
			buttons.push(buttonTmp);
		});

		return {
			title: datasFetched.title,
			description: datasFetched.description,
			buttons: buttons,
		};
	}
	formatSubForm(datas: any[]): SubForm {
		const datasFetched = datas.find((elt: any) => elt.component === 'subFormComponent').settings;
		return {
			title: datasFetched.title,
			text: datasFetched.text,
		};
	}

	formatStickyButtonsBox(datas: any[]) {
		const datasFetched = datas.find((elt: any) => elt.component === 'stickiesButtons').settings;
		const actions: ClassicStickiesButton[] = [];
		datasFetched.actions.forEach((action: any) => {
			const button: ButtonClassic = {
				text: action.value.bouton.text,
				color: action.value.bouton.color,
				level: action.value.bouton.level,
				link: action.value.bouton.link,
			};
			actions.push({
				label: action.value.label,
				button: button,
			});
		});
		return actions;
	}

	formatEventFormSection(datas: any): EventFormSection {
		const datasFetched = datas.find((elt: any) => elt.component === 'eventFormComponent').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
		};
	}

	formatTitleSubtitleSection(datas: any): EventFormSection {
		const datasFetched = datas.find((elt: any) => elt.component === 'titleSubtitleComponent').settings;
		return {
			title: datasFetched.title,
			subtitle: datasFetched.subtitle,
		};
	}
}
