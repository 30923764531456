import { Component, Input, OnInit } from '@angular/core';
import { NsiDivision } from 'src/app/interfaces/components.interface';

@Component({
	selector: 'app-nsi-division',
	templateUrl: './nsi-division.component.html',
	styleUrls: ['./nsi-division.component.scss'],
})
export class NsiDivisionComponent implements OnInit {
	@Input() datas!: NsiDivision;
	constructor() {}

	ngOnInit(): void {}
}
