import { Component, Input, OnInit } from '@angular/core';
import { HeroSecondary } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-hero-secondary',
	templateUrl: './hero-secondary.component.html',
	styleUrls: ['./hero-secondary.component.scss'],
})
export class HeroSecondaryComponent implements OnInit {
	@Input() datas!: HeroSecondary;
	pathImageApi = environment.imagesURL;

	constructor() {}

	ngOnInit(): void {}
}
