import { Component, Input, OnInit } from '@angular/core';
import { ContactBottom } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-contact-bottom',
	templateUrl: './contact-bottom.component.html',
	styleUrls: ['./contact-bottom.component.scss'],
})
export class ContactBottomComponent implements OnInit {
	@Input() datas!: ContactBottom;
	pathImageApi = environment.imagesURL;
	constructor() {}

	ngOnInit(): void {}
}
