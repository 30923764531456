import { Component, OnInit } from '@angular/core';
import { ContactBottom, HeroSecondary } from 'src/app/interfaces/components.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
	heroSecondary!: HeroSecondary;
	faqBottom!: ContactBottom;
	questions!: any[];

	constructor(private componentsService: ComponentsService, private collectionService: CollectionsService) {}

	ngOnInit(): void {
		this.getDatas();
		this.getAllQuestions();
	}

	async getAllQuestions() {
		this.questions = await this.collectionService.getAllFaq();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage('FaqPage', 'error to fetch home datas');
		const faqPageDatas = response.faqPage.length > 0 && response.faqPage[0].children;
		if (faqPageDatas) {
			this.heroSecondary = this.componentsService.formatHeroSecondary(faqPageDatas);
			this.faqBottom = this.componentsService.formatContactBottom(faqPageDatas);
		}
	}
}
