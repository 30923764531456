import { EventStates } from '../enums/event-state.enum';

export class EventProfile {
	constructor(
		// private _state: EventStates,
		private _text: string,
		private _ville: string,
		private _registration_condition: string | undefined,
		private _organization_type: string | undefined,
		private _start_date: Date | string,
		private _end_date: Date | string,
	) {}

	// get state() {
	// 	return this._state;
	// }

	get text(): string {
		return this._text;
	}
	get ville() {
		return this._ville;
	}
	get registrationCondition() {
		return this._registration_condition;
	}
	get organizationType() {
		return this._organization_type;
	}
	get startDate() {
		return this._start_date;
	}
	get endDate() {
		return this._end_date;
	}
}

export class EventProfileBuilder {
	// private state: EventStates = EventStates.OPEN;
	private text: string | EventStates = '';
	private ville: string;
	private registrationCondition?: string | undefined;
	private organizationType: string | undefined;
	private startDate: Date | string;
	private endDate: Date | string;

	constructor(
		ville: string,
		registrationCondition: string | undefined,
		organizationType: string | undefined,
		startDate: Date | string,
		endDate: Date | string,
	) {
		this.ville = ville;
		this.registrationCondition = registrationCondition;
		this.organizationType = organizationType;
		this.startDate = startDate;
		this.endDate = endDate;
	}

	// private setState(eventState: EventStates): EventStates {
	// 	return (this.state = eventState);
	// }

	private setText(text: string | EventStates) {
		return (this.text = text);
	}

	buildOpen(): EventProfile {
		this.setText('Entrée Libre');
		return this.build();
	}

	buildComplete(): EventProfile {
		this.setText(EventStates.COMPLETE);
		return this.build();
	}
	buildInProgress(): EventProfile {
		this.setText(EventStates.IN_PROGRESS);
		return this.build();
	}
	buildFinished(): EventProfile {
		this.setText(EventStates.FINISHED);
		return this.build();
	}
	buildLastEdition(): EventProfile {
		this.setText(EventStates.LAST_EDITION);
		return this.build();
	}

	private build() {
		return new EventProfile(
			// this.state,
			this.text,
			this.ville,
			this.registrationCondition,
			this.organizationType,
			this.startDate,
			this.endDate,
		);
	}
}
