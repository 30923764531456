<main class="main">
	<app-hero [datas]="heroPrimary"></app-hero>
	<app-countdown></app-countdown>
	<app-introduce-nsi [datas]="introduceSection"></app-introduce-nsi>
	<app-events-actions *ngIf="isDepositeOpen"></app-events-actions>
	<app-nsi-division [datas]="nsiDivision"></app-nsi-division>
	<app-next-events
		*ngIf="eventService.allEventsFromApi && eventService.allEventsFromApi.length > 0"
		[datas]="nextEvents"></app-next-events>
	<app-our-partners [datas]="ourPartners" [isHomePage]="true"></app-our-partners>
	<app-sponsor-cta [datas]="sponsorCTA"></app-sponsor-cta>
	<app-previous-editions [datas]="previousEditions"></app-previous-editions>
</main>
