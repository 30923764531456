import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StateAlert, StateAlertBuilder } from '../classes/state-alert';

interface CockpitMailForm {
	form: Record<string, any>;
}

@Injectable({
	providedIn: 'root',
})
export class MailsService {
	isMailSentToCockpit = true;
	isMailSentToSendinBlue = true;

	constructor(private httpClient: HttpClient) {}

	sendContributeEventMail(formValue: CockpitMailForm) {
		this.httpClient
			.post(`${environment.cockpitFormUrl}submit/contributeEvent?token=${environment.cockpitFormToken}`, formValue)
			.subscribe();

		return this.httpClient.post(`${environment.apiUrl}/mail/contribute`, formValue);
	}
}
