import { Component, Input, OnInit } from '@angular/core';
import { Resource } from 'src/app/interfaces/collections.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-resource-card',
	templateUrl: './resource-card.component.html',
	styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit {
	@Input() resource!: Resource;

	imageUrl = environment.imagesURL;

	constructor() {}

	ngOnInit(): void {}
}
