import { Component, OnInit } from '@angular/core';
import { ContactBottom, EventFormSection, HeroSecondary } from 'src/app/interfaces/components.interface';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.page.html',
	styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {
	heroSecondary!: HeroSecondary;
	eventForm!: EventFormSection;
	contactBottom!: ContactBottom;
	constructor(private componentsService: ComponentsService) {}

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'ContactPage',
			'error to fetch contact datas',
		);
		const contactPageDatas = response.contactPage.length > 0 && response.contactPage[0].children;
		if (contactPageDatas) {
			this.heroSecondary = this.componentsService.formatHeroSecondary(contactPageDatas);
			this.eventForm = this.componentsService.formatEventFormSection(contactPageDatas);
			this.contactBottom = this.componentsService.formatContactBottom(contactPageDatas);
		}
	}
}
