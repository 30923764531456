<div class="event-container" *ngIf="event" [routerLink]="['/evenement', event.id]">
	<div
		class="ec-background"
		[ngStyle]="{
			'background-image': event.event_img ? 'url(' + event.event_img + ')' : 'url(./assets/images/default-event.jpg)'
		}">
		<p *ngIf="event.complete" class="ecb-tag">Complet</p>
	</div>
	<div class="ec-text-wrapper">
		<h3 class="ectw-title">{{ event.name }}</h3>
		<div class="ectw-details-container">
			<div class="ectwdc-date-wrapper">
				<p class="ectwdcdw-date">{{ event.start_date | date: 'dd' }}</p>
				<p class="ectwdcdw-month">{{ event.start_date | date: 'LLL' }}</p>
			</div>
			<div class="ectwdc-address-wrapper">
				<p class="ectwdcaw-region">{{ event.city }}</p>
				<p class="ectwdcaw-region">{{ event.registration_conditions }}</p>
				<p class="ectwdcaw-region" *ngIf="event.max_capacity && event.max_capacity > 0">
					{{ event.max_capacity }} places
				</p>
			</div>
		</div>
	</div>
</div>
