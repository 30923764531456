import { Component, Input, OnInit } from '@angular/core';
import { Press } from 'src/app/interfaces/collections.interface';

@Component({
	selector: 'app-link-card',
	templateUrl: './link-card.component.html',
	styleUrls: ['./link-card.component.scss'],
})
export class LinkCardComponent implements OnInit {
	@Input() title!: string;
	@Input() elements: Press[] = [];

	constructor() {}

	ngOnInit(): void {}
}
