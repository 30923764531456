<article class="introduce-trophees" *ngIf="datas">
	<section class="it-container">
		<div class="itc-part right">
			<div class="itcp-item">
				<h3 class="itcpi-title part-title">{{ datas.firstParagraph.title }}</h3>
				<p class="itcpi-paragraph">{{ datas.firstParagraph.text }}</p>
			</div>
			<div class="itcp-item">
				<h3 class="itcpi-title part-title">{{ datas.secondParagraph.title }}</h3>
				<p class="itcpi-paragraph">{{ datas.secondParagraph.text }}</p>
			</div>
		</div>
		<div class="itc-part left">
			<div class="itcp-item">
				<div class="itcpi-title-wrapper">
					<div class="itcpitw-quote"></div>
					<h3 class="itcpi-title part-title">{{ datas.titleParagraph.title }}</h3>
					<div class="itcpitw-quote"></div>
				</div>
				<p class="itcpi-paragraph">{{ datas.titleParagraph.text }}</p>

				<custom-button
					color="secondary"
					level="primary"
					size="large"
					type="button"
					[callBackFunctionOnClick]="onButtonClick"
					>Voir les trophées NSI</custom-button
				>
			</div>
			<div class="itcp-item image">
				<img class="itcpi-image" src="./assets/svg/about.svg" alt="Illustration à propos" />
			</div>
		</div>
	</section>
</article>
