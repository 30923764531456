<footer class="footer" *ngIf="footerDatas">
	<div class="f-container">
		<div class="fc-part part-left">
			<img class="fcp-logo" [src]="imageUrl + footerDatas.logoWeek" routerLink="/" alt="logo NSI" />
			<ul class="fcp-links-list">
				<!-- Cockpit: links -->
				<li *ngFor="let link of footerDatas.links" routerLink="{{ link.path }}" class="fcpllp-item">{{ link.name }}</li>
				<!-- End cockpit links -->
			</ul>
		</div>
		<div class="fc-part part-right">
			<div class="fcp-socials">
				<!-- Cockpit: socialTitle -->
				<h3 class="fcps-title">{{ footerDatas.socialTitle }}</h3>
				<div class="fcps-item-wrapper">
					<a *ngFor="let social of socials" [href]="social.link" [attr.aria-label]="social.name" target="_blank" class="fcpsiw-link">
						<fa-icon [icon]="['fab', social.icon]"> </fa-icon>
					</a>
				</div>
			</div>
			<div class="fcp-form">
				<!-- Cockpit: alertsTitle -->
				<h3 for="newsletter" class="fcpf-label">{{ footerDatas.alertsTitle }}</h3>
				<!-- <input class="fcpf-newsletter" type="text" id="newsletter" /> -->
				<!-- Cockpit: alertsButton -->
				<custom-button
					type="button"
					color="primary"
					level="primary"
					size="small"
					ariaLabel="Ouvrir PopIn de la Newsletter"
					[callBackFunctionOnClick]="newsLetterPopupOpen"
					>{{ footerDatas.alertsButton }}</custom-button
				>
			</div>
		</div>
	</div>
</footer>
