import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-event-schedule-tab',
  templateUrl: './event-schedule-tab.component.html',
  styleUrls: ['./event-schedule-tab.component.scss'],
  animations: [
    trigger('onOff', [
      transition(':enter', [style({
        opacity: 0,
        transform: 'translateY(0)'
      }),
      animate(400)
      ])
    ])
  ]
})
export class EventScheduleTabComponent implements OnInit {

  @Input() dateData: any;
  @Input() number!: number;
  title: string;
  show: boolean = false;
  dateStart: string;
  dateEnd: string;
  activities: {
    hourStart: string,
    hourEnd: string,
    descr: string
  }[];

  constructor() {
    this.title = "Jour";
    this.dateStart = '';
    this.dateEnd = '';
    this.activities = [];
  }

  ngOnInit(): void {
    this.number++;

    // this.title = this.numberToFrench(this.number) + ' Jour';

    // Check and set days text
    this.dateStart = formatDate(this.dateData.startDate, 'dd/MM/yyyy', 'fr');
    this.dateEnd = formatDate(this.dateData.endDate, 'dd/MM/yyyy', 'fr');

    // Format activities data
    this.formatActivities();
  }

  toggleShow() {
    this.show = !this.show;
  }

  formatActivities() {
    this.dateData.timeSlots.sort((a: any,b: any) =>{
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    }); 


    for (let timeSlot of this.dateData.timeSlots) {
      this.activities.push({
        hourStart: formatDate(timeSlot.start, `H'h'mm`, 'fr'),
        hourEnd: formatDate(timeSlot.end, `H'h'mm`, 'fr'),
        descr: timeSlot.description
      });
    }
  }

  numberToFrench(n: number) {
    var number = n + 1;

    var string = number.toString(),
      units, tens, start, end, chunks, chunksLen, chunk, ints, i, word, words: string[];

    units = ['', 'Premier', 'Second', 'Troisième', 'Quatrième', 'Cinquième', 'Sixième', 'Septième', 'Huitième', 'Neuvième', 'Dixième', 'Onzième', 'Douxième', 'Treizième', 'Quatorzième', 'Quinzième', 'Seizième', 'Dix-septième', 'Dix-huitième', 'Dix neuvième'];

    tens = ['', '', 'vingtième', 'trentième', 'quarantième', 'cinquentième'];

    start = string.length;
    chunks = [];

    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    chunksLen = chunks.length;

    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {

        ints = chunks[i].split('').reverse().map(parseFloat);

        if (ints[1] === 1) {
          ints[0] += 10;
        }

        if ((word = units[ints[0]])) {
          words.push(word);
        }

        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        if ((word = units[ints[2]])) {
          words.push(word + ' cent');
        }

      }

    }

    return words.reverse().join(' ');
  }

}
