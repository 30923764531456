import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss']
})
export class SigninPage implements OnInit {

	illuContent: any;
	email = '';
	password = '';
	error: any = {};
	submitting = false;

	constructor(
		private httpClient: HttpClient,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {
		this.illuContent = {
			image1: "assets/images/hero-1.jpg",
			border: "#F09E2A",
			fill: "#BA3C12" 
		};
	}

	checkErrors(): boolean {
		if (this.submitting) {
			this.error.email = !this.email || this.email.length < 3 || !/(^\w.*@\w+\.\w)/.test(this.email);
			
      return !(
				this.error.email
			);
		} else {
			return false;
		}
	}

	postForm = () => {
		this.submitting = true;

		if (this.checkErrors()) {
			const contactFormResponse = {
        email: this.email,
        password: this.password,
			};

			this.httpClient
				.post(`${environment.apiUrl}/auth/login`, contactFormResponse)
				.subscribe((data: any) => {
					if (data) {
						if (data.access_token) {
							this.cookieService.set('token', data.access_token, {
								domain: "floax.fr",
								sameSite: 'Lax'
							});
							location.href = "https://nsiapp.staging.floax.fr"
						}
					}
				});
		}
	};
	
}
