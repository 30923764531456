import { Component, Input } from '@angular/core';
import { ListCases } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-case-list',
	templateUrl: './case-list.component.html',
	styleUrls: ['./case-list.component.scss'],
})
export class CaseListComponent {
	@Input() datas!: ListCases;
	imagesUrl: string = environment.imagesURL;
}
