<div class="event-schedule-tab">
    <!-- Content folder -->
    <button class="est-folder" (click)="toggleShow()">
        <span class="estf-label">Jour {{ number }}</span>
        <i class="eva {{show ? 'eva-chevron-up' : 'eva-chevron-down'}}"></i>
    </button>

    <div class="est-content" @onOff *ngIf="show">
        <!-- Column left -->
        <div class="content-half">
            <!-- (Date) -->
            <h3 class="estc-date" *ngIf="dateStart == dateEnd; else datebloc">Date: le {{ dateStart }}</h3>
            <ng-template #datebloc>
                <h4 class="estc-date">Date: du {{ dateStart }} au {{ dateEnd }}</h4>
            </ng-template>
            <ul class="activities-list">
                <li class="al-item" *ngFor="let activity of activities">
                    <p class="activity-txt">
                        <span class="activity-times">{{ activity.hourStart }} - {{ activity.hourEnd }}</span>: {{ activity.descr }}
                    </p>
                </li>
            </ul>
            <!-- (Details) -->
        </div>
        <!-- Column right -->
        <div class="content-half">
            <h3 class="estc-info-title">Informations complémentaires:</h3>
            <p class="estc-info-text">{{ dateData.description }}</p>
        </div>
    </div>
</div>