import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInstagram, faFacebook, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from './lib/accordion/accordion.module';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RedirectGuard } from './services/redirectguard.service';
import { VariablesGlobales } from './interfaces/global-variables.interface';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CustomButtonParticle } from './particles/custom-button/custom-button.particle';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomePage } from './pages/home/home.page';
import { CountdownComponent } from './components/countdown/countdown.component';
import { IntroduceNsiComponent } from './components/introduce-nsi/introduce-nsi.component';
import { IntroduceTropheesComponent } from './components/introduce-trophees/introduce-trophees.component';
import { EventsActionsComponent } from './components/events-actions/events-actions.component';
import { NsiDivisionComponent } from './components/nsi-division/nsi-division.component';
import { NextEventsComponent } from './components/next-events/next-events.component';
import { EventComponent } from './components/event/event.component';
import { OurPartnersComponent } from './components/our-partners/our-partners.component';
import { NewsletterPopupComponent } from './components/newsletter-popup/newsletter-popup.component';
import { AboutPage } from './pages/about/about.page';
import { EventsPage } from './pages/events/events.page';
import { ContactPage } from './pages/contact/contact.page';
import { ResourcesPage } from './pages/resources/resources.page';
import { PressPage } from './pages/press/press.page';
import { FaqPage } from './pages/faq/faq.page';
import { LegalsPage } from './pages/legals/legals.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { HeroSecondaryComponent } from './components/hero-secondary/hero-secondary.component';
import { ResourceCardComponent } from './components/resource-card/resource-card.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { FilterPipe } from './pipes/filter.pipe';
import { HeroEventsComponent } from './components/hero-events/hero-events.component';
import { AboutDetailsComponent } from './components/about-details/about-details.component';
import { ProjectPromotersComponent } from './components/project-promoters/project-promoters.component';
import { PromotersSliderComponent } from './components/promoters-slider/promoters-slider.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaqDetailsComponent } from './components/faq-details/faq-details.component';
import { FaqBottomComponent } from './components/faq-bottom/faq-bottom.component';
import { EventDetailsPage } from './pages/event-details/event-details.page';
import { EventProfileCardComponent } from './components/event-profile-card/event-profile-card.component';
import { EventUsefulComponent } from './components/event-useful/event-useful.component';
import { ListEventsAndMapComponent } from './components/list-events-and-map/list-events-and-map.component';
import { MapComponent } from './components/map/map.component';
import { UniquePipe } from './pipes/unique.pipe';
import { ContactBottomComponent } from './components/contact-bottom/contact-bottom.component';
import { SignUpPage } from './pages/signup/signup.page';
import { MaskedIllustrationComponent } from './components/masked-illustration/masked-illustration.component';
import { SigninPage } from './pages/signin/signin.page';
import { ForgetPasswordPage } from './pages/forget-password/forget-password.page';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { CookieService } from 'ngx-cookie-service';
import { HowToContributePage } from './pages/how-to-contribute/how-to-contribute.page';
import { HeroTernaryComponent } from './components/hero-ternary/hero-ternary.component';
import { CaseListComponent } from './components/case-list/case-list.component';
import { ClassicParagraphsComponent } from './components/classic-paragraphs/classic-paragraphs.component';
import { ContributePage } from './pages/contribute/contribute.page';
import { EventFormComponent } from './components/event-form/event-form.component';
import { ParticipatePage } from './pages/participate/participate.page';
import { SubFormComponent } from './components/sub-form/sub-form.component';
import { PartnersTypeSectionComponent } from './components/partners-type-section/partners-type-section.component';
import { Events2022Page } from './pages/events2022/events2022.page';
import { SponsorCtaComponent } from './components/sponsor-cta/sponsor-cta/sponsor-cta.component';
import { PreviousEditionsComponent } from './components/previous-editions/previous-editions.component';
import { StickiesActionsComponent } from './components/stickies-actions/stickies-actions.component';
import { StateAlertParticle } from './particles/state-alert/state-alert.particle';
import { EventScheduleTabComponent } from './components/event-schedule-tab/event-schedule-tab.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		CustomButtonParticle,
		FooterComponent,
		HeroComponent,
		HomePage,
		CountdownComponent,
		IntroduceNsiComponent,
		IntroduceTropheesComponent,
		EventsActionsComponent,
		NsiDivisionComponent,
		NextEventsComponent,
		EventComponent,
		OurPartnersComponent,
		NewsletterPopupComponent,
		AboutPage,
		EventsPage,
		ContactPage,
		ResourcesPage,
		PressPage,
		FaqPage,
		LegalsPage,
		NotFoundPage,
		HeroSecondaryComponent,
		ResourceCardComponent,
		SectionTitleComponent,
		LinkCardComponent,
		FilterPipe,
		HeroEventsComponent,
		AboutDetailsComponent,
		ProjectPromotersComponent,
		PromotersSliderComponent,
		FaqDetailsComponent,
		FaqBottomComponent,
		EventDetailsPage,
		EventProfileCardComponent,
		EventUsefulComponent,
		ListEventsAndMapComponent,
		MapComponent,
		UniquePipe,
		ContactFormComponent,
		ContactBottomComponent,
		SignUpPage,
		MaskedIllustrationComponent,
		SigninPage,
		ForgetPasswordPage,
		ChangePasswordPage,
		HowToContributePage,
		HeroTernaryComponent,
		CaseListComponent,
		ClassicParagraphsComponent,
		ContributePage,
		EventFormComponent,
		ParticipatePage,
		SubFormComponent,
		PartnersTypeSectionComponent,
		Events2022Page,
		SponsorCtaComponent,
		PreviousEditionsComponent,
		StickiesActionsComponent,
  StateAlertParticle,
  EventScheduleTabComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CarouselModule,
		AccordionModule,
		ToastrModule.forRoot(),
		FormsModule,
	],
	providers: [{ provide: LOCALE_ID, useValue: 'fr' }, DatePipe, CookieService, RedirectGuard, VariablesGlobales],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faInstagram, faFacebook, faLinkedin, faXmark, faMagnifyingGlass, faTwitter, faYoutube);
	}
}
