<section class="hero-section" style="background-color:rgba(0, 0, 0, 0.05);">
    <div class="container">
        <div class="hs-images-container">
            <app-masked-illustration
                [illuContent]="illuContent"
            ></app-masked-illustration>
        </div>
        <div class="hs-content">
            <custom-button 
                class="button-return"
                color="secondary" 
                level="primary" 
                size="large" 
                (click)="returnToLogin()"
                iconBefore="arrow-ios-back-outline"
                >Retour</custom-button>

            <div class="hsc-titles">
                <h1 class="hsct-title">Vous avez oublié votre mot de passe ?</h1>
                <p class="hsct-subtitle">Saississez votre adresse e-mail pour réinitialiser votre 
                    mot de passe</p>
            </div>
            <form class="hsc-form">
                <div class="form-row">
                    <div class="form-col">
                        <label for="mail">Adresse Email :</label>
                        <input type="email" id="mail" name="mail" [(ngModel)]="email" placeholder="adresse@mail.fr" (change)="checkErrors()">
                        <div *ngIf="error?.email" class="error">Vous devez saisir une adresse email valide.</div>
                    </div>
                </div>

                <div class="form-row submit">
                    <custom-button color="primary" level="primary" size="large" (click)="postForm()">Envoyer</custom-button>
                </div>
            </form>
        </div>
    </div>
</section>