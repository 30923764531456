<section class="hero-section" style="background-color:rgba(0, 0, 0, 0.05);">
    <div class="container">
        <div class="hs-images-container">
            <app-masked-illustration
                [illuContent]="illuContent"
            ></app-masked-illustration>
        </div>
        <div class="hs-content">
            <div class="hsc-titles">
                <h1 class="hsct-title">Se connecter</h1>
                <p class="hsct-subtitle">Accédez à votre espace de gestion d’événement</p>
            </div>
            <form class="hsc-form">
                <div class="form-row">
                    <div class="form-col">
                        <label for="mail">Adresse Email :</label>
                        <input type="email" id="mail" name="mail" [(ngModel)]="email" placeholder="adresse@mail.fr" (change)="checkErrors()">
                        <div *ngIf="error?.email" class="error">Vous devez saisir une adresse email valide.</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label for="password">Mot de passe :</label>
                        <input type="password" id="password" name="password" [(ngModel)]="password" placeholder="Votre mot de passe" (change)="checkErrors()">
                        <div *ngIf="error?.password" class="error">Vous devez saisir un mot de passe.</div>
                    </div>
                </div>

                <a [routerLink]="['/mot_de_passe_oublie']" class="forget-password">Mot de passe oublié ?</a>

                <div class="form-row submit">
                    <custom-button color="primary" level="primary" size="large" (click)="postForm()">Connexion</custom-button>
                </div>

                <span class="account-creation">Vous n'avez pas de compte ? <a [routerLink]="['/inscription']">Créez-en un !</a></span>
            </form>
        </div>
    </div>
</section>