<section class="contact-form">
	<div class="contact-container">
		<app-section-title
			*ngIf="datas"
			[title]="datas.title"
			[subtitle]="datas.subtitle"
			class="contact-title"></app-section-title>
		<div class="form-wrapper">
			<form [formGroup]="contactForm" (ngSubmit)="postForm()">
				<div class="form-row">
					<div class="form-col">
						<label for="last_name">Nom :</label>
						<input type="text" id="last_name" name="last_name" formControlName="last_name" placeholder="Votre nom" />
						<div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)" class="error">
							Vous devez saisir un nom.
						</div>
					</div>
					<div class="form-col">
						<label for="first_name">Prénom :</label>
						<input
							type="text"
							id="first_name"
							name="first_name"
							formControlName="first_name"
							placeholder="Votre prénom" />
						<div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)" class="error">
							Vous devez saisir un prénom.
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="mail">Adresse Email :</label>
						<input type="email" id="mail" name="mail" formControlName="email" placeholder="adresse@mail.fr" />
						<div *ngIf="(email?.invalid && email?.dirty) || (email?.invalid && email?.touched)" class="error">
							Vous devez saisir une adresse email valide.
						</div>
					</div>
					<div class="form-col">
						<label for="phone">Téléphone :</label>
						<input type="phone" id="phone" name="phone" formControlName="phone" placeholder="06 12 34 56 78" />
						<div *ngIf="(phone?.invalid && phone?.dirty) || (phone?.invalid && phone?.touched)" class="error">
							Vous devez saisir un numéro de téléphone valide.
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="description">Message :</label>
						<textarea
							id="description"
							name="description"
							formControlName="description"
							placeholder="Votre message..."></textarea>
						<div *ngIf="description?.invalid && (description?.dirty || description?.touched)" class="error">
							Vous devez saisir un message.
						</div>
					</div>
				</div>

				<div class="form-row">
					<label class="checkbox-label" for="isRGPD">
						<input type="checkbox" formControlName="isRGPD" id="isRGPD" />
						En cochant cette case, j'accepte que mes données soient utilisées pour me recontacter par la suite.
					</label>
				</div>

				<div class="fw-success" *ngIf="success">
					Votre message a bien été envoyé, merci. Nous reviendrons vers vous très prochainement.
				</div>
				<div class="fw-error" *ngIf="submitError">Votre message n'a pas pu être envoyé, veuillez réessayer.</div>

				<div class="form-row submit">
					<custom-button [isDisabled]="contactForm.invalid" color="accent" level="primary">
						Envoyer le formulaire
					</custom-button>
				</div>

				<div class="form-row faq">
				</div>
			</form>
		</div>
	</div>
</section>
