<section class="find-next-events">
	<div class="fne-container">
		<div class="fnec-header section-header" id="events" *ngIf="datas">
			<div class="fnec-slash sh-slash"></div>
			<div class="fnec-title-subtitle-wrapper sh-title-wrapper">
				<h3 class="fnec-title shtw-title">{{ datas.title }}</h3>
				<p class="fnec-subtitle shtw-subtitle">{{ datas.subtitle }}</p>
			</div>
		</div>
		<form class="fnec-filters" [formGroup]="filterForm">
			<div class="fnecf-map-trigger" (click)="toggleMap()">
				<i *ngIf="!isMapOpen" class="fnecfmt-icon eva eva-map"></i>
				<span *ngIf="!isMapOpen" class="fnecfmt-label">Vue Carte</span>
				<i *ngIf="isMapOpen" class="fnecfmt-icon eva eva-grid"></i>
				<span *ngIf="isMapOpen" class="fnecfmt-label">Vue Liste</span>
			</div>
			<div class="fnecf-select-wrapper" *ngIf="eventPublics">
				<label class="screen-reader-text" for="target">Public visé</label>
				<select class="fnecfsw-select" id="target" formControlName="publicEvent">
					<option value="">Public Visé</option>
					<option *ngFor="let public of eventPublics" [value]="public">{{ public }}</option>
				</select>
			</div>
			<div class="fnecf-select-wrapper" *ngIf="eventTypes">
				<label class="screen-reader-text" for="type">Type d'événement</label>
				<select class="fnecfsw-select" id="type" formControlName="typeEvent">
					<option value="">Type d'événement</option>
					<option *ngFor="let type of eventTypes" [value]="type">{{ type }}</option>
				</select>
			</div>
			<div class="fnecf-select-wrapper" *ngIf="eventDates">
				<label class="screen-reader-text" for="date">Dates</label>
				<select class="fnecfsw-select" id="date" formControlName="dateEvent">
					<option value="" selected="">Dates</option>
					<option *ngFor="let date of eventDates" [value]="date">{{ date | date: 'dd/MM/yyyy' }}</option>
				</select>
			</div>

			<!-- <div class="fnecf-date-wrapper" *ngIf="eventTypes">
				<div class="fnecfdw-date-ctn">
					<label class="fnecfdw-datetxt" for="date">Date: </label>
					<input class="fnecfdw-date" type="date" id="date" (change)="onDateChanged($event)" />
				</div>
			</div> -->
			<div class="fnecf-text-wrapper">
				<span *ngIf="chosenType || chosenPublic || eventService.isListFiltered">{{ filteredEvents.length }} résultat{{ filteredEvents.length > 1 ? 's' : '' }}</span>
			</div>
			<custom-button class="fnecf-reset" color="primary" level="secondary" iconBefore="close-outline" [callBackFunctionOnClick]="resetFilters" type="button" size="small" *ngIf="chosenType || chosenPublic || eventService.isListFiltered">réinitialiser les filtres</custom-button>
		</form>
		<div class="previous-event" *ngIf="previousYear">
			<i class="eva eva-alert-circle"></i>
			<p class="pe-text">Attention, les événements suivants sont terminés et font partie d’une édition précédente</p>
		</div>
		<div class="fnec-events-map-container">
			<div class="fnecemc-events" [ngClass]="isMapOpen ? 'mapOpen' : ''">
				<app-event *ngFor="let event of filteredEvents" [event]="event"></app-event>
			</div>
			<div class="fnecemc-maps" [ngClass]="isMapOpen ? 'open' : ''" #map>
				<app-map [isMapOpen]="isMapOpen"></app-map>
			</div>
		</div>
		<div class="fnec-more-button">
			<custom-button class="fnecf-reset" color="primary" level="primary" iconAfter="arrow-ios-downward-outline" [callBackFunctionOnClick]="addMoreEvents" *ngIf="isAddMoreButtonDisplayed">afficher plus d'évènements</custom-button>
		</div>
	</div>
</section>