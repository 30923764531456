export class StateAlert {
	constructor(private _description: string, private _type: string, private _duration: number, private _icon: string) {}

	get description() {
		return this._description;
	}
	get type() {
		return this._type;
	}

	get duration() {
		return this._duration;
	}

	get icon() {
		return this._icon;
	}
}

export class StateAlertBuilder {
	private description: string;
	private type = 'success';
	private duration = 3000;
	private icon = 'done-all-outline';

	constructor(description: string) {
		this.description = description;
	}

	private setType(type: string): string {
		return (this.type = type);
	}
	private setIcon(icon: string): string {
		return (this.icon = icon);
	}

	buildSuccess(): StateAlert {
		this.setType('success');
		this.setIcon('checkmark-circle-outline');
		return this.build();
	}

	buildError(): StateAlert {
		this.setType('error');
		this.setIcon('alert-triangle-outline');
		return this.build();
	}

	private build() {
		return new StateAlert(this.description, this.type, this.duration, this.icon);
	}
}
