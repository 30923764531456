import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	options!: IndividualConfig;
	constructor(private toastr: ToastrService) {
		this.options = this.toastr.toastrConfig;
		this.options.positionClass = 'toast-bottom-center';
		this.options.timeOut = 2500;
	}

	showToast(title: string, message: string, type: string) {
		this.toastr.show(message, title, this.options, 'toast-' + type);
	}
}
