<article class="countdown">
	<section class="c-countdown-container" *ngIf="distance > 0">
		<div class="ccc-item">
			<p class="ccci-metric">{{ daysLeft }}</p>
			<p class="ccci-paragraph">Jours restants</p>
		</div>
		<div class="ccc-item">
			<p class="ccci-metric">{{ hoursLeft }}</p>
			<p class="ccci-paragraph">heures</p>
		</div>
		<div class="ccc-item illustration"></div>
		<div class="ccc-item">
			<p class="ccci-metric">{{ minutesLeft }}</p>
			<p class="ccci-paragraph">minutes</p>
		</div>
		<div class="ccc-item">
			<p class="ccci-metric">{{ secondsLeft }}</p>
			<p class="ccci-paragraph">secondes</p>
		</div>
	</section>
	<section class="c-countdown-container ended" *ngIf="distance <= 0">
		<div class="ccc-item illustration"></div>
		<div class="ccc-item-last-message">
			<!-- <p class="cccilm-message">Découvrez tous les événements organisés près de chez vous !</p> -->
			<p class="cccilm-message">On se retrouve bientôt !</p>
			<p class="cccilm-date"></p>
		</div>
	</section>
</article>