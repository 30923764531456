import { Component, OnInit } from '@angular/core';
import { Subscriber } from 'rxjs';
import {
	HeroPrimary,
	IntroduceSection,
	NextEvents,
	NsiDivision,
	OurPartners,
	SponsorCTA,
	PreviousEditions
} from 'src/app/interfaces/components.interface';
import { VariablesGlobales } from 'src/app/interfaces/global-variables.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';
import { EventService } from 'src/app/services/event.service';

// interface SingletonResponse

@Component({
	selector: 'app-home',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
	constructor(
		private componentsService: ComponentsService,
		public eventService: EventService,
		public collectionsService: CollectionsService,
		private globalVar: VariablesGlobales
	) { }

	heroPrimary!: HeroPrimary;
	introduceSection!: IntroduceSection;
	nsiDivision!: NsiDivision;
	nextEvents!: NextEvents;
	ourPartners!: OurPartners;
	sponsorCTA!: SponsorCTA;
	previousEditions!: PreviousEditions;
	isDepositeOpen: boolean = this.globalVar.isDepositeOpen;

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage('Home', 'error to fetch home datas');
		const homePageDatas = response.homePage.length > 0 && response.homePage[0].children;
		if (homePageDatas) {
			this.heroPrimary = this.componentsService.formatHeroPrimary(homePageDatas);
			this.introduceSection = this.componentsService.formatIntroduceSection(homePageDatas);
			this.nsiDivision = this.componentsService.formatNsiDivision(homePageDatas);
			this.nextEvents = this.componentsService.formatNextEvents(homePageDatas);
			this.ourPartners = this.componentsService.formatOurPartners(homePageDatas);
			this.sponsorCTA = this.componentsService.formatSponsorCTA(homePageDatas);
			this.previousEditions = this.componentsService.formatPreviousEditions(homePageDatas);
		}
	}
}
