<main class="main">
	<div *ngIf="state === 'complete'" class="date-wrapper {{ state }}">
		<p class="dw-date">{{ eventProfile?.text }}</p>
	</div>
	<div *ngIf="state === 'open' && event.registration_conditions === 'Sur inscription' && event.max_capacity" class="date-wrapper {{ state }}">
		<p class="dw-date">{{ eventLeftTickets() }}</p>
		<p class="dw-month">place(s) disponible(s)</p>
	</div>
	<div *ngIf="state === 'open' && event.registration_conditions === 'Sur inscription' && !event.max_capacity" class="date-wrapper {{ state }}">
		<p class="dw-month">Place(s) disponible(s)</p>
	</div>
	<div *ngIf="state === 'open' && event.registration_conditions === 'Entrée libre'" class="date-wrapper {{ state }}">
		<p class="dw-month">{{ eventProfile?.text }}</p>
	</div>
	<div *ngIf="state === 'passed'" class="date-wrapper {{ state }}">
		<p class="dw-state">{{ eventProfile?.text }}</p>
	</div>
	<div *ngIf="state === 'in-progress'" class="date-wrapper {{ state }}">
		<p class="dw-state">{{ eventProfile?.text }}</p>
	</div>
	<div class="content-wrapper">
		<div class="cw-infos">
			<p class="cwi-city">{{ event.city }}</p>
			<p class="cwi-registration">{{ event.registration_conditions }}</p>
			<p class="cwi-organisation">{{ event.organisation_name }}</p>
			<p class="cwi-hours" *ngFor="let period of event.eventDates">
				{{ period.startDate | date: 'EEEE':'GMT+1' | titlecase }} : {{ period.startHour | date: 'HH':'GMT+1' }}h{{period.startHour | date: 'mm':'GMT+1'}} - {{ period.endHour | date: 'HH':'GMT+1' }}h{{period.endHour | date: 'mm':'GMT+1'}} <br/>
				<i>(Heure de Paris, GMT+2)</i>
			</p>
		</div>
		<custom-button *ngIf="state === 'open' && event.isRegistrationRequired" routerLink="/participer/{{ event.id }}" color="accent" level="primary">S'inscrire en ligne</custom-button>
	</div>
</main>