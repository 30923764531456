import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConfirmedValidator } from '../signup/confirmed.validators';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.page.html',
	styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {
	illuContent: any;
	submitting = false;
	changePasswordForm!: FormGroup;

	constructor(private httpClient: HttpClient, private formbuilder: FormBuilder) {
		this.illuContent = this.illuContent = {
			image1: 'assets/images/hero-1.jpg',
			border: '#F09E2A',
			fill: '#F09E2A',
		};
	}

	ngOnInit(): void {
		this.changePasswordForm = this.formbuilder.group(
			{
				newPassword: ['', [Validators.required, Validators.minLength(6)]],
				confirmPassword: ['', [Validators.required]],
			},
			{
				validator: ConfirmedValidator('newPassword', 'confirmPassword'),
			},
		);
	}

	onSubmit = () => {
		const formValue = this.changePasswordForm.value;
		
		this.httpClient.post(`${environment.apiUrl}`, { newPassword: formValue['newPassword'] }).subscribe({
			next: (data) => {
				
			},
			error: (error) => {
				console.log(error);
			},
		});
	};

	get newPassword() {
		return this.changePasswordForm.get('newPassword');
	}
	get confirmPassword() {
		return this.changePasswordForm.get('confirmPassword');
	}
}
