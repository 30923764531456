<main class="main">
	<app-hero-secondary [datas]="heroSecondary"></app-hero-secondary>
	<section class="mentions">
		<div class="m-container">
			<div class="mc-paragraph" *ngFor="let p of mentions">
				<h2 class="mcp-title">{{ p.title }}</h2>
				<p class="mcp-text">{{ p.text }}</p>
			</div>
		</div>
	</section>
</main>
