<article class="events-actions">
	<section class="ea-container">
		<div
			*ngIf="eventService.allEventsFromApi && eventService.allEventsFromApi.length > 0"
			class="eac-part eac-part-left">
			<div class="eacp-custom-button">
				<custom-button
					routerLink="/evenements"
					color="secondary"
					level="primary"
					size="large"
					iconAfter="arrow-ios-forward-outline"
					>RECHERCHER UN ÉVÉNEMENT</custom-button
				>
			</div>
			<img class="eacp-image" src="./assets/svg/participate.svg" alt="illustration rechercher un évènement" />
		</div>
		<div class="eac-part eac-part-right">
			<div class="eacp-custom-button">
				<a href="https://app.semaine-nsi.fr/signin">
					<custom-button color="secondary" level="primary" size="large" iconAfter="arrow-ios-forward-outline"
						>Déposer un événement</custom-button
					>
				</a>
			</div>
			<img class="eacp-image" src="./assets/svg/organization.svg" alt="illustration organiser un évènement" />
		</div>
	</section>
</article>
