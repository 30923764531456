import { Component, Input, OnInit } from '@angular/core';
import { IntroduceTrophees } from 'src/app/interfaces/components.interface';

@Component({
	selector: 'app-introduce-trophees',
	templateUrl: './introduce-trophees.component.html',
	styleUrls: ['./introduce-trophees.component.scss'],
})
export class IntroduceTropheesComponent implements OnInit {
	@Input() datas!: IntroduceTrophees;
	constructor() {}

	ngOnInit(): void {}

	onButtonClick = () => {
		window.location.href = 'https://trophees-nsi.fr/';
	};
}
