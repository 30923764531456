<section class="event-form classic-section">
	<div class="ef-container classic-container">
		<app-section-title *ngIf="datas" [title]="datas.title" [subtitle]="datas.subtitle"></app-section-title>
		<form class="efc-form-container" [formGroup]="isContributeForm() ? contributeForm : participateForm" (ngSubmit)="onSubmitForm()">
			<app-state-alert [stateAlert]="stateAlert" test="youpi"></app-state-alert>
			<div class="efcfc-field">
				<div class="efcfcf-item">
					<label for="lastname">Nom</label>
					<input type="text" formControlName="lastname" id="lastname" placeholder="Votre nom" />
					<p *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner le nom
					</p>
				</div>
				<div class="efcfcf-item">
					<label for="firstname">Prénom</label>
					<input type="text" formControlName="firstname" id="firstname" placeholder="Votre prénom" />
					<p *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner le prénom
					</p>
				</div>
			</div>
			<div class="efcfc-field">
				<div class="efcfcf-item">
					<label for="email">Adresse e-mail</label>
					<input type="text" formControlName="email" id="email" placeholder="moncontact@gmail.fr" />
					<p *ngIf="email?.errors?.['required'] && (email?.dirty || email?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner un email
					</p>
					<p *ngIf="email?.errors?.['email'] && (email?.dirty || email?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner un email valide
					</p>
				</div>
				<div class="efcfcf-item">
					<label for="phone">Téléphone</label>
					<input type="text" formControlName="phone" id="phone" placeholder="05 03 03 02 02" />
					<p *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner un numéro de téléphone valide
					</p>
				</div>
			</div>
			<div class="efcfc-field">
				<div class="efcfcf-item">
					<label for="organization">Organisation (facultatif)</label>
					<input type="text" formControlName="organization" id="organization" />
				</div>
				<div class="efcfcf-item">
					<label for="userFunction">Fonction (facultatif)</label>
					<input type="text" formControlName="userFunction" id="userFunction" />
				</div>
			</div>
			<div class="efcfc-field"  *ngIf="!isContributeForm()">
				<div class="efcfcf-item">
					<label for="city">Ville</label>
					<input type="text" formControlName="city" id="city" />
					<p *ngIf="city?.invalid && (city?.dirty || city?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner une ville
					</p>
				</div>
				<div class="efcfcf-item">
					<label for="zipcode">Code postal</label>
					<input type="text" formControlName="zipcode" id="zipcode" />
					<p *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)" class="efcfcfi-error input-error">
						Veuillez renseigner un code postal
					</p>
				</div>
			</div>
			<div class="efcfc-field" *ngIf="isContributeForm()">
				<div class="efcfcf-item">
					<label for="selectedRegion">Région d’intervention :</label>
					<select class="select-input" formControlName="selectedRegion" id="selectedRegion" *ngIf="regionList">
						<option value="">
							Sélectionnez votre région d’intervention <i class="eva eva-chevron-down-outline"></i>
						</option>
						<option *ngFor="let region of regionList" [value]="region">{{ region }}</option>
					</select>
				</div>
				<div class="efcfcf-item">
					<label for="interventionZone">Zone d'intervention</label>
					<input type="text" formControlName="interventionZone" id="interventionZone" />
				</div>
			</div>
			<div class="efcfc-field" *ngIf="!isContributeForm()">
				<div class="efcfcf-item">
					<label for="nbrOfParticipants">Nombre de participants</label>
					<div class="efcfcfi-input-wrapper number-wrapper">
						<button class="efcfcfiiw-button nw-button decrement" type="button" (click)="handleDecrement()">-</button>
						<input class="number-input" type="text" formControlName="nbrOfParticipants" id="nbrOfParticipants" />
						<button class="efcfcfiiw-button nw-button increment" type="button" (click)="handleIncrement()">+</button>
					</div>
				</div>
				<div class="efcfcf-item">
					<label for="selectedRegion">Public :</label>
					<select formControlName="public" id="public" *ngIf="regionList">
						<option class="placeholder" value="" selected disabled hidden>Sélectionnez un public</option>
						<option *ngFor="let type of publicTypes" [value]="type">{{ type }}</option>
					</select>
				</div>
			</div>
			<div class="efcfc-field" *ngIf="isContributeForm()">
				<div class="efcfcf-item">
					<h3 class="efcfcf-title">Événement en ligne ?</h3>
					<label class="checkbox-label" for="isOnline">
						<input type="checkbox" formControlName="isOnline" id="isOnline" />
						Cochez cette case si vous souhaitez organiser un événement en ligne.
					</label>
				</div>
			</div>
			<div class="efcfc-field" formArrayName="disponibilities" *ngIf="isContributeForm()">
				<div class="efcfcf-item">
					<h3 class="efcfcf-title">Indiquez vos jours de disponibilité :</h3>
					<div class="efcfcfi-dateitem" *ngFor="let disponibilityControl of getDisponibilities().controls; let i = index">
						<input type="date" [formControlName]="i" id="disponibilities-start-{{ i }}" class="date-input" />
						<!-- <i class="eva eva-calendar-outline"></i> -->
					</div>
					<custom-button type="button" color="secondary" level="ghost" size="small" iconBefore="plus-outline" (click)="onAddDisponibility()">
						Ajouter D’autres disponibilités
					</custom-button>
				</div>
			</div>
			<div class="efcfc-field">
				<div class="efcfcf-item">
					<label for="description">{{ isContributeForm() ? 'Description de l’intervention' : 'Informations complémentaires' }} :</label>
					<textarea type="text" [formControlName]="isContributeForm() ? 'description' : 'informations'" id="description"></textarea>
				</div>
			</div>
			<div class="efcfc-field">
				<div class="efcfcf-item">
					<label class="checkbox-label" for="isRGPD">
						<input type="checkbox" formControlName="isRGPD" id="isRGPD" />
						En cochant cette case, j'accepte que mes données soient utilisées pour me recontacter par la suite.
					</label>
				</div>
			</div>
			<custom-button [isDisabled]="isContributeForm() ? contributeForm.invalid : participateForm.invalid" color="accent" level="primary" size="small" iconAfter="arrow-ios-forward-outline">
				envoyer le formulaire
			</custom-button>
		</form>

		<div class="efc-questions">
			<p class="efcq-txt">Un doute ? Une question ?</p>
			<p class="efcq-txt">
				La réponse à votre question se trouve peut être sur notre page
				<a class="efcq-link" href="/faq" target="_blank" title="Visitez notre foire aux questions">FAQ</a>.
			</p>
		</div>
	</div>
</section>