import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { VariablesGlobales } from 'src/app/interfaces/global-variables.interface';

interface NavBarItemInterface {
	text: string;
	link: string;
}

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	isNavMobileOpen?: boolean = false;
	isNavSticky: boolean = false;
	isDepositeOpen: boolean = this.globalVar.isDepositeOpen;

	navBarItems: NavBarItemInterface[] = [
		{
			text: 'comment participer ?',
			link: '/comment-participer'
		},
		{
			text: 'Devenir Partenaire',
			link: '/contact',
		},
		{
			text: 'à propos',
			link: '/a-propos',
		},
		{
			text: 'contact',
			link: '/contact',
		},
	];

	constructor(public eventService: EventService, private globalVar: VariablesGlobales) { }

	ngOnInit(): void {
		// window.addEventListener('scroll', this.scroll, true);
	}

	toggleNavMobile() {
		this.isNavMobileOpen = !this.isNavMobileOpen;
	}

	closeNavMobile() {
		this.isNavMobileOpen = false;
	}

	// scroll = (): void => {
	// 	if (window.scrollY >= 100) {
	// 		this.isNavSticky = true;
	// 	} else if (window.scrollY < 100) {
	// 		this.isNavSticky = false;
	// 	}
	// };
}
