<main class="main">
	<app-hero-secondary [datas]="heroSecondary"></app-hero-secondary>
	<section class="highlight-resources">
		<div class="hr-container">
			<app-section-title
				*ngIf="titleSection"
				[title]="titleSection.title"
				[subtitle]="titleSection.subtitle"></app-section-title>
			<div class="hrc-wrapper" *ngIf="resources">
				<app-resource-card *ngFor="let resource of resources.slice(0, 3)" [resource]="resource"></app-resource-card>
			</div>
		</div>
	</section>
	<section class="all-resources">
		<app-link-card [title]="'Document PDF'" [elements]="resources | filter: 'Présentation PDF'"></app-link-card>
		<app-link-card [title]="'Fichier JPEG'" [elements]="resources | filter: 'Fichier JPEG'"></app-link-card>
		<app-link-card [title]="'Liens'" [elements]="resources | filter: 'Liens'"></app-link-card>
	</section>
</main>
