import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
	Faq,
	Partner,
	Press,
	Promoter,
	Resource,
	Social,
	WeekDatesStartEnd,
	Link,
	Edition,
} from '../interfaces/collections.interface';
import { CockpitCollectionResponse } from '../interfaces/cockpit-responses.interface';

@Injectable({
	providedIn: 'root',
})
export class CollectionsService {
	private allPartners!: Partner[];
	private allPromoters!: Promoter[];
	private allSocials!: Social[];

	constructor(private httpClient: HttpClient) {}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.log(error);
			console.log(`${operation} failed: ${error.message}`);

			return of(result as T);
		};
	}

	private getAllItemsOfCollection(collectionName: string, withPublished: boolean) {
		return this.httpClient
			.post(`${environment.cockpitURL}api/collections/get/${collectionName}`, {
				filter: withPublished
					? {
							published: true,
					  }
					: false,
				populate: 1,
				lang: 'fr',
			})
			.pipe(catchError(this.handleError(`error when fetch ${collectionName}`, [])))
			.toPromise();
	}

	async getAllPartners(): Promise<Partner[]> {
		const response: any = await this.getAllItemsOfCollection('partners', true);
		return this.formatPartnersResponse(response.entries);
	}

	async getAllPartnersByType(type: string): Promise<Partner[]> {
		const response: any = await this.getAllItemsOfCollection('partners', true);
		if (response.entries) {
			response.entries = response.entries.filter((part: any) => {
				return part.category == type;
			});
		}
		return this.formatPartnersByTypeResponse(response.entries);
	}

	async getAllPromoters(): Promise<Promoter[]> {
		const response: any = await this.getAllItemsOfCollection('promoters', true);
		return this.formatPromotersResponse(response.entries);
	}

	async getAllSocials(): Promise<Social[]> {
		const response: any = await this.getAllItemsOfCollection('Socials', false);
		return this.formatSocialsResponse(response.entries);
	}

	async getAllFooterLinks(): Promise<Link[]> {
		const response: any = await this.getAllItemsOfCollection('footerLinks', false);
		return this.formatFooterLinksResponse(response.entries);
	}

	async getAllFaq(): Promise<Faq[]> {
		const response: any = await this.getAllItemsOfCollection('faq', false);
		let allFaq: Faq[] = [];

		response.entries.forEach((faq: Faq) => {
			allFaq = [...allFaq, { question: faq.question, answer: faq.answer }];
		});
		return allFaq;
	}

	async getAllPreviousEditions(): Promise<Edition[]> {
		const response: any = await this.getAllItemsOfCollection('previousEditions', false);
		return this.formatPreviousEditionsResponse(response.entries);
	}

	async getAllResources(): Promise<Resource[]> {
		const response: any = await this.getAllItemsOfCollection('ressources', false);
		let allResources: Resource[] = [];

		response.entries.forEach((resource: any) => {
			allResources = [
				...allResources,
				{
					name: resource.name,
					image: resource.image?.path ? resource.image?.path : null,
					type: resource.type,
					date: resource.date,
					link: resource.link,
				},
			];
		});
		return allResources;
	}
	async getAllPress(): Promise<Press[]> {
		const response: any = await this.getAllItemsOfCollection('press', false);
		let allPress: Press[] = [];

		response.entries.forEach((press: any) => {
			allPress = [
				...allPress,
				{
					name: press.name,
					type: press.type,
					date: press.date,
					link: press.link,
					fileLink: environment.imagesURL + press.fileLink,
				},
			];
		});
		return allPress;
	}

	async getNsiDay(): Promise<Date> {
		const response: any = await this.getAllItemsOfCollection('eventDate', false);
		const date = response.entries[0].date;
		const hour = response.entries[0].hour;
		const completeDate = `${date}T${hour}`;
		return new Date(completeDate);
	}

	async getNsiWeekDates(): Promise<WeekDatesStartEnd> {
		const response = (await this.getAllItemsOfCollection('eventDates', false)) as CockpitCollectionResponse;
		const entries = response.entries[0];
		return {
			startDate: entries.startDate,
			endDate: entries.endDate,
		};
	}

	private formatPartnersResponse(response: any[]): Partner[] {
		let arrayOfFormatedPartners: Partner[] = [];
		response.forEach((partner) => {
			arrayOfFormatedPartners = [
				...arrayOfFormatedPartners,
				{
					name: partner.name,
					image: partner.image.path,
					description: partner.description,
					hiddenOnHomePage: partner.hiddenOnHomePage ? partner.hiddenOnHomePage : false,
				},
			];
		});
		return arrayOfFormatedPartners;
	}

	private formatPartnersByTypeResponse(response: any[]): Partner[] {
		let arrayOfFormatePartnersByType: Partner[] = [];
		response.forEach((partnerByType) => {
			arrayOfFormatePartnersByType = [
				...arrayOfFormatePartnersByType,
				{
					name: partnerByType.name,
					image: partnerByType.image.path,
					description: partnerByType.description,
					category: partnerByType.category,
				},
			];
		});
		return arrayOfFormatePartnersByType;
	}

	private formatPromotersResponse(response: any[]) {
		let arrayOfFormatedPromoters: Promoter[] = [];
		response.forEach((promoter) => {
			arrayOfFormatedPromoters = [
				...arrayOfFormatedPromoters,
				{
					name: promoter.name,
					image: promoter.image.path,
					text: promoter.text,
				},
			];
		});
		return arrayOfFormatedPromoters;
	}

	private formatPreviousEditionsResponse(response: any[]) {
		let arrayOfFormatedPreviousEditions: Edition[] = [];
		response.forEach((edition) => {
			arrayOfFormatedPreviousEditions = [
				...arrayOfFormatedPreviousEditions,
				{
					name: edition.name,
					year: edition.year,
					link: edition.link,
				},
			];
		});
		return arrayOfFormatedPreviousEditions;
	}

	private formatSocialsResponse(response: any[]): Social[] {
		let arrayOfFormatedSocials: Social[] = [];
		response.forEach((social) => {
			arrayOfFormatedSocials = [
				...arrayOfFormatedSocials,
				{
					name: social.name,
					link: social.link,
					icon: social.icon,
				},
			];
		});
		return arrayOfFormatedSocials;
	}

	private formatFooterLinksResponse(response: any[]): Link[] {
		let arrayOfFormatedFooterLinks: Link[] = [];
		response.forEach((link) => {
			arrayOfFormatedFooterLinks = [
				...arrayOfFormatedFooterLinks,
				{
					name: link.name,
					path: link.path,
				},
			];
		});
		return arrayOfFormatedFooterLinks;
	}
}
