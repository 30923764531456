import { Component, OnInit, Input } from '@angular/core';
import { Partner } from 'src/app/interfaces/collections.interface';
import { PartnersTypeData } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
  selector: 'app-partners-type-section',
  templateUrl: './partners-type-section.component.html',
  styleUrls: ['./partners-type-section.component.scss']
})
export class PartnersTypeSectionComponent implements OnInit {

  @Input() datas!: PartnersTypeData;
  @Input() partnersType!: string;

  partners!: Partner[];
  pathImageApi = environment.imagesURL;

  constructor(private collectionService: CollectionsService) {}

  ngOnInit(): void {
    this.getAllPartnersByType();
  }

  async getAllPartnersByType() {
    const response: Partner[] = await this.collectionService.getAllPartnersByType(this.partnersType);
    this.partners = response;
  }

}
