import { Component, Input, OnInit } from '@angular/core';
import { NextEvents } from 'src/app/interfaces/components.interface';
import { Event } from 'src/app/interfaces/event.interface';
import { EventService } from 'src/app/services/event.service';

@Component({
	selector: 'app-next-events',
	templateUrl: './next-events.component.html',
	styleUrls: ['./next-events.component.scss'],
})
export class NextEventsComponent implements OnInit {
	@Input() datas!: NextEvents;
	@Input() eventEndDate!: any;
	nextEvents!: Event[];
	eventYear!: number;
	isPast!: boolean;

	constructor(private eventService: EventService) {}

	ngOnInit(): void {
		this.getNextEvents();
		this.isPast = this.checkIfPast();
	}

	async getNextEvents(): Promise<void> {
		this.nextEvents = await this.eventService.getNextEvents();
	}

	getEventYear() {
		return new Date(this.eventEndDate).getFullYear();
	}

	checkIfPast(): boolean {
		this.eventYear = this.getEventYear();
		let currentYear = new Date().getFullYear();
		return this.eventYear < currentYear;
	}
}
