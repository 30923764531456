<div class="fbc-image">
    <img src="./assets/images/faq.png" />
</div>
<article class="faq-bottom">
	<section class="fb-container">
		<div class="fbc-part right">
			<div class="fbcp-item">
				<p class="fbcpi-paragraph">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium, fringilla senectus consequat morbi molestie pellentesque elementum, velit ac. Ipsum in nec praesent arcu ut. Pellentesque neque ullamcorper tellus dictum est. Erat ante convallis dictum orci sapien. In egestas sit feugiat fringilla nunc cursus sed ac. Nec commodo sapien pellentesque ac. Enim turpis leo, et senectus maecenas a. Vel, consectetur habitasse maecenas vulputate in tellus egestas. Lorem sit proin magna metus aliquam sagittis, purus consectetur.
				</p>
			</div>
		</div>
		<div class="fbc-part left">
			<div class="fbcp-title-wrapper">
				<div class="fbcptw-quote"></div>
				<h3 class="fbcp-title part-title">Titre</h3>
				<div class="fbcptw-quote"></div>
			</div>
		</div>
	</section>
</article>
