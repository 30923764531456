import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Event } from 'src/app/interfaces/event.interface';

@Injectable({
	providedIn: 'root',
})
export class GeocodeService {
	constructor(private httpClient: HttpClient) {}

	private log(log: string) {
		console.info(log);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.log(error);
			console.log(`${operation} failed : ${error.message}`);
			return of(result as T);
		};
	}

	getGeocodeFromAddress(event: Event, errorMessage: string) {
		return this.httpClient
			.get(this.formatUrl(event))
			.pipe(
				tap((_) => this.log('get geocode')),
				catchError(this.handleError(`${errorMessage}`, [])),
			)
			.toPromise();
	}

	formatUrl(event: Event) {
		const apiKey = '112758867d81b2bba39f7ad6b41e69df';
		const address = `${event.address}, ${event.zip_code}, ${event.city}`;
		const geocodeUrl = `http://api.positionstack.com/v1/forward?access_key=${apiKey}&query=${address}`;
		return geocodeUrl;
	}
}
