<app-hero-ternary [datas]="heroTernary"></app-hero-ternary>
<div class="classic-section about-section">
    <div class="as-main">
        <app-about-details [datas]="aboutDetails"></app-about-details>
        <app-classic-paragraphs [datas]="paragraphFirstPart"></app-classic-paragraphs>
    </div>
    <div class="as-aside">
        <app-stickies-actions [datas]="stickiesButtons"></app-stickies-actions>
    </div>
</div>
<app-case-list [datas]="listCases"></app-case-list>
<app-classic-paragraphs [datas]="paragraphSecondPart"></app-classic-paragraphs>