import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event } from 'src/app/interfaces/event.interface';
import { nextEvents } from 'src/app/data/fake-events.data';
import { EventService } from 'src/app/services/event.service';
// import { CollectionsService } from 'src/app/services/collections.service';
// import { WeekDatesStartEnd } from 'src/app/interfaces/collections.interface';
import { Subscription } from 'rxjs';
import { AllEventsSection } from 'src/app/interfaces/components.interface';
// import { formatDate } from '@angular/common';

enum SelectFilterType {
	TYPE_EVENT = 'typeEvent',
	PUBLIC_EVENT = 'publicEvent',
	DATE_EVENT = 'dateEvent'
}

@Component({
	selector: 'app-list-events-and-map',
	templateUrl: './list-events-and-map.component.html',
	styleUrls: ['./list-events-and-map.component.scss'],
})
export class ListEventsAndMapComponent implements OnInit {
	@Input() datas!: AllEventsSection;
	@Input() year!: string;
	@Input() previousYear!: boolean;

	nextEvents!: Event[];
	eventPublics: string[] = [];
	eventTypes: string[] = [];
	eventDates: Date[] = [];
	eventsDisplayed: Event[] = [];
	filteredEvents!: Event[];
	chosenPublic = '';
	chosenType = '';
	chosenDate!: Date;
	isAddMoreButtonDisplayed = true;

	indexSliceMoreEvent = 6;

	// weekDatesStartEnd!: WeekDatesStartEnd;
	// startDate!: string;
	// endDate!: string;
	eventsDisplayedSubscription!: Subscription;
	isAddMoreButtonDisplayedSubscription!: Subscription;
	eventTypeSubscription!: Subscription;
	eventPublicsSubscription!: Subscription;
	eventDatesSubscription!: Subscription;

	isMapOpen = false;

	filterForm!: FormGroup;

	constructor(public eventService: EventService, private formBuilder: FormBuilder) { }

	async ngOnInit() {
		this.eventsDisplayedSubscription = this.eventService.eventDisplayedSubject.subscribe((events: Event[]) => {
			this.filteredEvents = events;
		});

		this.isAddMoreButtonDisplayedSubscription = this.eventService.isAddMoreButtonDisplayedSubject.subscribe(
			(result: boolean) => {
				this.isAddMoreButtonDisplayed = result;
			},
		);

		this.eventTypeSubscription = this.eventService.eventTypesSubject.subscribe((types: string[]) => {
			this.eventTypes = types;
		});

		this.eventPublicsSubscription = this.eventService.targetedPublicSubject.subscribe((targeted: string[]) => {
			this.eventPublics = targeted;
		});

		this.eventDatesSubscription = this.eventService.eventDatesSubject.subscribe((targeted: Date[]) => {
			this.eventDates = targeted;
		});

		this.filterForm = this.formBuilder.group({
			publicEvent: [''],
			typeEvent: [''],
			dateEvent: [''],
		});

		this.filteredEvents = this.eventService.eventDisplayed;

		this.eventPublics = this.eventService.targetedPublic;
		this.eventTypes = this.eventService.eventTypes;
		this.eventDates = await this.eventService.getAllDatesFromDisplayedEvents();

		this.onSelectChange(SelectFilterType.PUBLIC_EVENT);
		this.onSelectChange(SelectFilterType.TYPE_EVENT);
		this.onSelectChange(SelectFilterType.DATE_EVENT);
	}

	async ngOnChanges() {
		this.eventDates = await this.eventService.getAllDatesFromDisplayedEvents();
	}

	toggleMap = () => {
		this.isMapOpen = !this.isMapOpen;
	};

	onSelectChange(typeSelect: string) {
		this.filterForm.get(typeSelect)?.valueChanges.subscribe((val) => {
			// this.eventService.resetEventDisplayed();
			if (typeSelect === SelectFilterType.PUBLIC_EVENT) this.chosenPublic = val;
			if (typeSelect === SelectFilterType.TYPE_EVENT) this.chosenType = val;
			if (typeSelect === SelectFilterType.DATE_EVENT) this.chosenDate = val;
			
			this.eventService.filterByTargetsOrByTypes(this.chosenPublic, this.chosenType, this.chosenDate);
		});
	}

	resetFilters = () => {
		this.filterForm.setValue({
			publicEvent: '',
			typeEvent: '',
			dateEvent: ''
		});
		this.eventService.resetEventDisplayed();
	};

	addMoreEvents = () => {
		this.eventService.addMoreEvents();
	};
}
