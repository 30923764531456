<section class="hero-section">
	<div class="container">
		<div class="hs-images-container">
			<app-masked-illustration [illuContent]="illuContent"></app-masked-illustration>
		</div>
		<div class="hs-content">
			<div class="hsc-titles">
				<h1 class="hsct-title">S'inscrire</h1>
				<p class="hsct-subtitle">Vous pourrez proposer, créer, gérer vos évènements</p>
			</div>
			<form class="hsc-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
				<div class="form-row">
					<div class="form-col">
						<label for="lastname">Nom :</label>
						<input type="text" id="lastname" name="lastname" formControlName="lastname" placeholder="Votre nom" />
						<div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)" class="error">
							Vous devez saisir un nom.
						</div>
					</div>
					<div class="form-col">
						<label for="firstname">Prénom :</label>
						<input type="text" id="firstname" name="firstname" formControlName="firstname" placeholder="Votre prénom" />
						<div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)" class="error">
							Vous devez saisir un prénom.
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="departement">Département :</label>
						<input
							type="text"
							id="departement"
							name="departement"
							formControlName="departement"
							placeholder="Votre département"
							list="department" />
						<div *ngIf="departement?.invalid && (departement?.dirty || departement?.touched)" class="error">
							Vous devez saisir un département.
						</div>
						<datalist id="department">
							<option *ngFor="let department of allDepartments" [value]="department.num_dep">
								{{department.dep_name}}
							</option>
						</datalist>
					</div>
					<div class="form-col">
						<label for="organisme">Organisme (facultatif) :</label>
						<input
							type="text"
							id="organisme"
							name="organisme"
							formControlName="organisme"
							placeholder="Votre organisme" />
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="mail">Adresse Email :</label>
						<input type="email" id="mail" name="mail" formControlName="email" placeholder="adresse@mail.fr" />
						<div *ngIf="email?.invalid && (email?.touched)" class="error">
							Vous devez saisir une adresse email valide.
						</div>
					</div>
					<div class="form-col">
						<label for="phone">Téléphone :</label>
						<input type="phone" id="phone" name="phone" formControlName="phone" placeholder="06 12 34 56 78" />
						<div *ngIf="phone?.invalid && ( phone?.touched)" class="error">
							Vous devez saisir un numéro de téléphone valide.
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="password">Mot de passe :</label>
						<input
							type="password"
							id="password"
							name="password"
							formControlName="password"
							placeholder="Votre mot de passe" />
						<div *ngIf="password?.invalid && ( password?.touched)" class="error">
							Vous devez saisir un mot de passe valide.
						</div>
					</div>
					<div class="form-col">
						<label for="passwordRepeat">Répéter le mot de passe :</label>
						<input
							type="password"
							id="passwordRepeat"
							name="passwordRepeat"
							formControlName="passwordRepeat"
							placeholder="Votre mot de passe" />
						<div *ngIf="f.passwordRepeat.errors?.confirmedValidator" class="error">
							Le mot de passe doit correspondre
						</div>
					</div>
				</div>
				<div class="form-row agreement-wrapper">
					<input type="checkbox" name="isSubscribed" id="isSubscribed" formControlName="isSubscribed" />
					<label for="isSubscribed">
						J’accepte de recevoir des courriels très occasionnels à propos de l’organisation de la Semaine du numérique et des sciences informatiques.
					</label>
				</div>
				<div class="form-row submit">
					<custom-button color="accent" level="ghost" type="button">Annuler</custom-button>
					<custom-button [isDisabled]="signUpForm.invalid" color="accent" level="primary">S'inscrire</custom-button>
				</div>
			</form>
		</div>
	</div>
</section>
