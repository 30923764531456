import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import * as L from 'leaflet';
import { Event } from 'src/app/interfaces/event.interface';
import { GeocodeService } from 'src/app/services/geocode.service';
import { DatePipe } from '@angular/common';
import { EventService } from 'src/app/services/event.service';
import { Subscription } from 'rxjs';

interface EventOnMap {
	name: string;
	lat: number;
	lon: number;
}

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
	events!: Event[];
	@Input() isMapOpen!: boolean;

	eventsForMapSubscription!: Subscription;

	// eventsOnMap: EventOnMap[] = [];
	private map: any;
	private markerIcon: any = L.icon({
		iconUrl: './assets/logos/nsi-min.svg',
		iconSize: [25, 25],
	});

	constructor(private geocodeService: GeocodeService, private datePipe: DatePipe, public eventService: EventService) {}

	ngOnInit(): void {
		this.events = this.eventService.allEventsFromApi;
		this.eventsForMapSubscription = this.eventService.allEventsFromApiSubject.subscribe((events: Event[]) => {
			this.events = events;
		});
	}

	ngOnChanges(changes: any): void {
		if (this.map) setTimeout(() => this.map.invalidateSize(), 150);
		if (this.map) {
			this.map.remove();
			this.initMap();
		}
	}

	ngAfterViewInit() {
		this.initMap();
	}

	private async initMap() {
		this.map = L.map('map', {
			center: [46.227, 2.213],
			zoom: 6,
		});

		this.map.locate({ setView: true, maxZoom: 12 });

		const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			minZoom: 3,
			attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
		});
		this.map.invalidateSize();
		this.getAllEventOnMap(this.events);

		tiles.addTo(this.map);
	}

	addMarker(event: Event) {
		L.marker([Number(event.lat), Number(event.lng)], { icon: this.markerIcon })
			.addTo(this.map)
			.bindPopup(
				`
				<a class="event-container" href="evenement/${event.id}">
					<div class="ec-text-wrapper">
						<h4 class="ectw-title">${event.name}</h4>
						<div class="ectw-details-container">
							<div class="ectwdc-date-wrapper">
								<p class="ectwdcdw-date">${this.datePipe.transform(event.start_date, 'dd')}</p>
								<p class="ectwdcdw-month">${this.datePipe.transform(event.start_date, 'LLL')}</p>
							</div>
							<div class="ectwdc-address-wrapper">
								<p class="ectwdcaw-region">${event.city}</p>
								<p class="ectwdcaw-region">${event.registration_conditions}</p>
								<p class="ectwdcaw-region">${event.max_capacity ? event.max_capacity + " places" : ""}</p>
							</div>
						</div>
					</div>
				</a>		
			`,
				{ className: 'floax-popup' },
			);
	}

	getAllEventOnMap(events: Event[]) {
		events?.forEach((event) => {
			this.addMarker(event);
		});
	}
}
