import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { allDepartments, Department } from 'src/app/data/departments.data';
import { HeroEvents } from 'src/app/interfaces/components.interface';
import { Event } from 'src/app/interfaces/event.interface';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-hero-events',
	templateUrl: './hero-events.component.html',
	styleUrls: ['./hero-events.component.scss'],
})
export class HeroEventsComponent implements OnInit {
	@Input() datas!: HeroEvents;
	pathImageApi = environment.imagesURL;
	backgroundImage = './assets/images/hero-events.jpg';
	filteredEvents!: Event[];
	eventsDisplayedSubscription!: Subscription;
	allDepartmentList = allDepartments;
	departmentList: string[] = [];
	regionList: string[] = [];

	heroForm!: FormGroup;

	constructor(
		public eventService: EventService,
		private formBuilder: FormBuilder,
		private viewportScroller: ViewportScroller,
	) {}

	ngOnInit(): void {
		this.eventsDisplayedSubscription = this.eventService.eventDisplayedSubject.subscribe((events: Event[]) => {
			this.filteredEvents = events;
		});

		this.filteredEvents = this.eventService.allEventsFromApi;

		this.heroForm = this.formBuilder.group({
			description: [''],
			region: [''],
			department: [''],
			city: [''],
		});

		this.resetDepartments();
	}

	onSubmit = () => {
		this.eventService.resetEventDisplayed();
		const formValue = this.heroForm.value;

		if (formValue['description']) {
			this.eventService.eventFilterDescription = formValue['description'].toLowerCase();
			this.eventService.updateEventDisplayed(
				this.filteredEvents.filter((elt) => elt.name.toLowerCase().includes(formValue['description'].toLowerCase())),
			);
		}
		if (formValue['region']) {
			this.eventService.eventFilterRegion = formValue['region'];
			this.eventService.updateEventDisplayed(this.filteredEvents.filter((elt) => elt.region == formValue['region']));
		}
		if (formValue['department']) {
			this.eventService.eventFilterDepartment = formValue['department'];
			this.eventService.updateEventDisplayed(
				this.filteredEvents.filter((elt) => elt.department == formValue['department']),
			);
		}
		if (formValue['city']) {
			this.eventService.eventFilterCity = formValue['city'].trim().toLowerCase(); 
			console.log(this.filteredEvents);
			this.eventService.updateEventDisplayed(
				this.filteredEvents.filter((elt: Event) => {
						return !elt.city ? false : elt.city.trim().toLowerCase().includes(formValue['city'].trim().toLowerCase())
				}),
			);
		}

		document.getElementById('events')?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		});
	};

	resetSearch = () => {
		this.heroForm.setValue({
			description: '',
			region: '',
			department: '',
			city: '',
		});
		this.allDepartmentList.forEach((elt: Department) => {
			this.departmentList = [...this.departmentList, elt.dep_name].sort();
		});
		this.eventService.resetEventDisplayed();
	};

	resetDepartments = () => {
		this.regionList = [];
		this.departmentList = [];

		this.allDepartmentList.forEach((dept) => {
			if (this.regionList.indexOf(dept.region_name) === -1) {
				this.regionList.push(dept.region_name);
			}
			if (this.departmentList.indexOf(dept.dep_name) === -1) {
				this.departmentList.push(dept.dep_name);
			}
		});

		this.regionList.sort();

		this.departmentList.sort();
	};

	updateRegion = () => {
		this.resetDepartments();

		const formValue = this.heroForm.value;

		if (formValue['region']) {
			this.departmentList = [];

			this.allDepartmentList.forEach((dept) => {
				if (dept.region_name === formValue['region']) {
					this.departmentList.push(dept.dep_name);
				}
			});
			this.departmentList.push('');
			this.departmentList.sort();
		}
	};

	removeAccents(str: string) {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}
}
