import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AboutPage } from './pages/about/about.page';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { ContactPage } from './pages/contact/contact.page';
import { EventDetailsPage } from './pages/event-details/event-details.page';
import { EventsPage } from './pages/events/events.page';
import { Events2022Page } from './pages/events2022/events2022.page';
import { FaqPage } from './pages/faq/faq.page';
import { ForgetPasswordPage } from './pages/forget-password/forget-password.page';
import { HomePage } from './pages/home/home.page';
import { HowToContributePage } from './pages/how-to-contribute/how-to-contribute.page';
import { ContributePage } from './pages/contribute/contribute.page';
import { LegalsPage } from './pages/legals/legals.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { PressPage } from './pages/press/press.page';
import { ResourcesPage } from './pages/resources/resources.page';
import { SigninPage } from './pages/signin/signin.page';
import { SignUpPage } from './pages/signup/signup.page';
import { ParticipatePage } from './pages/participate/participate.page';
import { RedirectGuard } from './services/redirectguard.service';

const routes: Routes = [
	{
		path: '',
		component: HomePage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Accueil',
			},
		},
	},
	{
		path: 'a-propos',
		component: AboutPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'A propos',
			},
		},
	},
	{
		path: 'evenements',
		component: EventsPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Evénements',
			},
		},
	},
	{
		path: 'evenement/:id',
		component: EventDetailsPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Mon événement',
			},
		},
	},
	{
		path: 'evenements-2022',
		component: Events2022Page,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Evénements 2022',
			},
		},
	},
	{
		path: 'contact',
		component: ContactPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Contact',
			},
		},
	},
	{
		path: 'comment-participer',
		component: HowToContributePage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Comment participer',
			},
		},
	},
	{
		path: 'contribuer',
		component: ContributePage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Contribuer',
			},
		},
	},
	{
		path: 'participer/:eventId',
		component: ParticipatePage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'participer',
			},
		},
	},
	{
		path: 'ressources-utiles',
		component: ResourcesPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Ressources utiles',
			},
		},
	},
	{
		path: 'espace-presse',
		component: PressPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Espace presse',
			},
		},
	},
	{
		path: 'faq',
		component: FaqPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Faq',
			},
		},
	},
	{
		path: 'mentions-legales',
		component: LegalsPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Mentions légales',
			},
		},
	},
	{
		path: 'inscription',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://app.semaine-nsi.fr/signup',
		},
	},
	{
		path: 'signup',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://app.semaine-nsi.fr/signup',
		},
	},
	{
		path: 'connexion',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://app.semaine-nsi.fr/signin',
		},
	},
	{
		path: 'mot_de_passe_oublie',
		component: ForgetPasswordPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Mot de passe oublié',
			},
		},
	},
	{
		path: 'changement-mot-de-passe',
		component: ChangePasswordPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Changement de mot de passe',
			},
		},
	},
	{
		component: NotFoundPage,
		path: '**',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
			scrollOffset: [0, 100],
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(router: Router) {
		setTimeout(() => {
			router.navigate([], {
				preserveFragment: true,
				skipLocationChange: true,
			});
		}, 200);
	}
}
