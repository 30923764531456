<section class="next-events">
	<div class="ne-container">
		<div class="nec-header">
			<app-section-title *ngIf="datas" [title]="datas.title" [subtitle]="datas.subtitle"></app-section-title>
		</div>
		<div class="nec-events-wrapper" *ngIf="nextEvents">
			<app-event *ngFor="let event of nextEvents" [event]="event"></app-event>
		</div>
		<div class="nec-buttons-wrapper">
			<custom-button routerLink="/signup" color="primary" level="primary" size="medium" iconAfter="arrow-ios-forward-outline">Proposer un événement</custom-button>
			<custom-button [routerLink]="isPast ? '/evenements-' + eventYear : '/evenements'" color="primary" level="secondary" size="medium" iconAfter="arrow-ios-forward-outline">voir tous les évènements</custom-button>
		</div>
	</div>
</section>