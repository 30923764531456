import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventFormSection } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
	@Input() datas!: EventFormSection;
	contactFormUrl: string = environment.contactFormUrl;
	phonePattern = '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$';

	submitted = false;
	submitting = false;
	success = false;
	submitError = false;

	contactForm!: FormGroup;

	constructor(private httpClient: HttpClient, private formBuilder: FormBuilder, private router: Router) {}

	ngOnInit(): void {
		this.contactForm = this.formBuilder.group({
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', Validators.pattern(this.phonePattern)],
			description: ['', Validators.required],
			isRGPD: ['', Validators.requiredTrue]
		});
	}

	postForm = () => {
		const formValue = { form: this.contactForm.value };
		this.httpClient.post(`${environment.contactFormUrl}?token=${environment.contactFormToken}`, formValue).subscribe(
			() => (this.success = true),
			() => (this.submitError = true),
		);

		// Call API route
		this.httpClient.post(`${environment.apiUrl}/mail/contact`, formValue).subscribe(
			() => (this.success = true),
			() => (this.submitError = true),
		);

		this.contactForm.reset();
		this.router.navigate(['/contact']);
	};

	get lastname() {
		return this.contactForm.get('last_name');
	}

	get firstname() {
		return this.contactForm.get('first_name');
	}

	get email() {
		return this.contactForm.get('email');
	}
	get phone() {
		return this.contactForm.get('phone');
	}

	get description() {
		return this.contactForm.get('description');
	}
}
