import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { allDepartments } from 'src/app/data/departments.data';
import { WeekDatesStartEnd } from 'src/app/interfaces/collections.interface';
import { NewsLetterForm, ChosenDepartment } from 'src/app/interfaces/newsletter-form.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { formatWeekDatesStartEnd } from 'src/app/utils/dates.utils';

@Component({
	selector: 'app-newsletter-popup',
	templateUrl: './newsletter-popup.component.html',
	styleUrls: ['./newsletter-popup.component.scss'],
})
export class NewsletterPopupComponent implements OnInit {
	isNewsletterPopUpOpen = false;
	newsletterPopupSubscription!: Subscription;

	allDepartments = allDepartments;
	newsletterForm!: FormGroup;
	chosenDepartments: string[] = [];
	chosenDepartmentsDisplayed: ChosenDepartment[] = [];
	isAtLeastOneCheckBoxChecked = false;

	weekDatesStartEnd!: WeekDatesStartEnd;
	eventDatesStringifyed!: string;

	constructor(
		private formBuilder: FormBuilder,
		private newsletterService: NewsletterService,
		private collectionsService: CollectionsService,
	) {}

	ngOnInit(): void {
		this.newsletterPopupSubscription = this.newsletterService.popupSubject.subscribe((isPopupOpen: boolean) => {
			this.isNewsletterPopUpOpen = isPopupOpen;
		});
		this.newsletterService.emitPopupSubject();

		this.newsletterForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			departments: [[]],
			agreed: [false, [Validators.requiredTrue]],
			notifiedOnDepartmentsEvents: [false],
			notifiedOnOnlineEvents: [false],
			notifiedOnWeeklyResume: [false],
		});

		this.onDepartmentsChanges();
		this.onNotifyingChange();
		this.getWeekDatesStartEnd();
	}

	getWeekDatesStartEnd = async () => {
		this.weekDatesStartEnd = await this.collectionsService.getNsiWeekDates();
		this.eventDatesStringifyed = formatWeekDatesStartEnd(
			this.weekDatesStartEnd.startDate,
			this.weekDatesStartEnd.endDate,
		);
	};

	closeNewsLetterPopup = () => {
		this.newsletterService.closePopup();
	};

	onDepartmentsChanges(): void {
		this.newsletterForm.get('departments')?.valueChanges.subscribe((val) => {
			const isValAdepartment = this.allDepartments.filter((dpt) => dpt.num_dep === val).length ? true : false;
			if (this.chosenDepartments.indexOf(val) === -1 && isValAdepartment) {
				this.chosenDepartments = [...this.chosenDepartments, val];
				const departmentNameToAdd: ChosenDepartment | undefined = this.allDepartments.find(
					(elt) => elt.num_dep === val,
				);
				if (departmentNameToAdd)
					this.chosenDepartmentsDisplayed = [...this.chosenDepartmentsDisplayed, departmentNameToAdd];
				this.newsletterForm.controls['departments'].reset('', { emitEvent: false });
			} else if (this.chosenDepartments.indexOf(val) != -1 && isValAdepartment) {
				this.newsletterForm.controls['departments'].reset('', { emitEvent: false });
			}
		});
	}

	onNotifyingChange(): void {
		const notifiedOnDepartmentsEvents = this.newsletterForm.get('notifiedOnDepartmentsEvents');
		const notifiedOnOnlineEvents = this.newsletterForm.get('notifiedOnOnlineEvents');
		notifiedOnDepartmentsEvents?.valueChanges.subscribe((val) => {
			this.isAtLeastOneCheckBoxChecked = val || notifiedOnOnlineEvents?.value;
		});
		notifiedOnOnlineEvents?.valueChanges.subscribe((val) => {
			this.isAtLeastOneCheckBoxChecked = val || notifiedOnDepartmentsEvents?.value;
		});
	}

	unChooseDepartment(depNumb: string) {
		const departmentNameToRemove: ChosenDepartment | undefined = this.allDepartments.find(
			(elt) => elt.num_dep === depNumb,
		);

		if (departmentNameToRemove) {
			this.chosenDepartmentsDisplayed = this.chosenDepartmentsDisplayed.filter(
				(elt) => elt.num_dep != departmentNameToRemove.num_dep,
			);
			this.chosenDepartments = this.chosenDepartments.filter((elt) => elt != depNumb);
		}
	}

	onSubmit() {
		const formValue = this.newsletterForm.value;

		const response: NewsLetterForm = {
			email: formValue['email'],
			departments: this.chosenDepartmentsDisplayed,
			notifiedOnDepartmentsEvents: formValue['notifiedOnDepartmentsEvents'],
			notifiedOnOnlineEvents: formValue['notifiedOnOnlineEvents'],
			notifiedOnWeeklyResume: formValue['notifiedOnWeeklyResume'],
			notifiedOnAllEvents: !formValue['notifiedOnWeeklyResume'],
		};
		this.newsletterService.postInscription(response);
		this.chosenDepartments = [];
		this.chosenDepartmentsDisplayed = [];
		this.newsletterForm.reset();
		this.newsletterService.closePopup();
	}
}
