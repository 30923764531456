export const environment = {
	production: true,
	imagesURL: 'https://semaine-nsi.fr/',
	cockpitURL: 'https://semaine-nsi.fr/admin/',
	apiUrl: 'https://api.semaine-nsi.floax.dev',
	contactFormUrl: 'https://semaine-nsi.fr/admin/api/forms/submit/contact',
	cockpitFormUrl: 'https://semaine-nsi.fr/admin/api/forms/',
	contactFormToken: '08bef6672bae221849a7dda36bb042',
	cockpitFormToken: '6b8e03ef89ad03e0e4ab026e8dfd21',
	allSingletonsToken: 'cc40ae9c3862bcab9484de274cb2cb',
	domain: 'semaine-nsi.fr',
};
