<article class="nsi-division" *ngIf="datas">
	<section class="nd-container">
		<p class="ndc-paragraph">{{ datas.text }}</p>
		<div class="ndc-title-wrapper">
			<div class="ndctw-quote"></div>
			<h2 class="ndctw-title">{{ datas.title }}</h2>
			<div class="ndctw-quote"></div>
		</div>
	</section>
</article>
