import { Component, OnInit } from '@angular/core';
import { HeroSecondary } from 'src/app/interfaces/components.interface';
import { TitleWithSubtitle } from 'src/app/interfaces/title-with-subtitle.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';
import { Resource } from '../../interfaces/collections.interface';

@Component({
	selector: 'app-resources',
	templateUrl: './resources.page.html',
	styleUrls: ['./resources.page.scss'],
})
export class ResourcesPage implements OnInit {
	heroSecondary!: HeroSecondary;
	titleSection!: TitleWithSubtitle;
	resources!: Resource[];

	constructor(private componentsService: ComponentsService, private collectionService: CollectionsService) {
		this.getAllResources();
	}

	ngOnInit(): void {
		this.getDatas();
	}

	async getAllResources() {
		this.resources = await this.collectionService.getAllResources();
	}

	filterResources(type: string) {
		return this.resources.filter((el) => el.type == type);
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'ResourcesPage',
			'error to fetch home datas',
		);
		const resourcesPageDatas = response.resourcesPage.length > 0 && response.resourcesPage[0].children;
		if (resourcesPageDatas) {
			this.heroSecondary = this.componentsService.formatHeroSecondary(resourcesPageDatas);
			this.titleSection = this.componentsService.formatTitleSubtitleSection(resourcesPageDatas);
		}
	}
}
