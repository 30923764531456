<div class="container">
	<div class="carousel" *ngIf="promoters">
		<owl-carousel-o [options]="customOptions">
			<ng-container *ngFor="let promoter of promoters; let i = index">
				<ng-template carouselSlide [id]="'slide-' + (i + 1)" [width]="400">
					<div class="psc-slide">
						<div class="pscs-image">
							<img
								class="pscsi-img"
								[src]="pathImageApi + promoter.image"
								[alt]="promoter.name"
								[title]="promoter.name" />
						</div>
						<div class="pscs-text">
							<div class="pscst-name">{{ promoter.name }}</div>
							<div class="pscst-details">{{ promoter.text }}</div>
						</div>
					</div>
				</ng-template>
			</ng-container>
		</owl-carousel-o>
	</div>
</div>
