<div class="cbc-image" *ngIf="datas">
	<img [src]="pathImageApi + datas.image" [alt]="datas.title" />
</div>
<article class="contact-bottom" *ngIf="datas">
	<section class="cb-container">
		<div class="cbc-part left">
			<div class="cbcp-item">
				<app-section-title
					class="cbcpi-title"
					[title]="datas.genericTitle"
					[subtitle]="datas.genericSubtitle"></app-section-title>
				<p class="cbcpi-paragraph">{{ datas.callInterv }}</p>
				<custom-button
					*ngIf="datas.isButton && datas.intervButton"
					[color]="datas.intervButton.color"
					[level]="datas.intervButton.level"
					routerLink="/{{ datas.intervButton.link }}"
					size="small"
					iconAfter="arrow-ios-forward-outline"
					>{{ datas.intervButton.text }}</custom-button
				>
			</div>
		</div>
		<div class="cbc-part right">
			<div class="cbcp-title-wrapper">
				<div class="cbcptw-quote"></div>
				<h3 class="cbcp-title part-title">{{ datas.title }}</h3>
				<div class="cbcptw-quote"></div>
			</div>
		</div>
	</section>
</article>
