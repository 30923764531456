import { Component, OnInit } from '@angular/core';
import { HeroTernary, EventFormSection, SubForm } from 'src/app/interfaces/components.interface';
import { ComponentsService } from 'src/app/services/components.service';
import { WichForm } from '../../enums/event-form.enum';

@Component({
	selector: 'app-participate',
	templateUrl: './participate.page.html',
	styleUrls: ['./participate.page.scss'],
})
export class ParticipatePage implements OnInit {
	heroTernary!: HeroTernary;
	eventForm!: EventFormSection;
	subForm!: SubForm;
	wichForm: WichForm = WichForm.REGISTER;

	constructor(private componentsService: ComponentsService) {}

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'ParticipatePage',
			'error to fetch HowToContribute datas',
		);
		const participatePage = response.participatePage.length > 0 && response.participatePage[0].children;
		if (participatePage) {
			this.heroTernary = this.componentsService.formatHeroTernary(participatePage);
			this.eventForm = this.componentsService.formatEventFormSection(participatePage);
			this.subForm = this.componentsService.formatSubForm(participatePage);
		}
	}
}
