<div class="previous-editions" *ngIf="datas">
    <div class="title-ctn">
        <app-section-title [title]="datas.title" [subtitle]="datas.subtitle"></app-section-title>
    </div>
    <div class="pe-content">

        <ng-container *ngFor="let edition of editions | slice:0:3; let i = index">
            <div class="pec-item" [ngStyle]="{ 'background-image': 'url(' + imageUrl + datas.images[i] + ')' }">
                <div class="filter __primary"></div>
                <div class="peci-card" *ngIf="edition.name">
                    <div class="pecic-quote __pre"></div>
                    <h3 class="pecic-name">
                        {{ edition.name }}
                        <div class="pecicn-year">{{edition.year }}</div>
                    </h3>
                    <custom-button (click)="redirectToLink(edition.link)" *ngIf="edition" color="primary" level="primary" size="medium" iconAfter="arrow-ios-forward-outline">
                        Voir l'édition
                    </custom-button>
                    <div class="pecic-quote __post"></div>
                </div>
            </div>
        </ng-container> 
    </div>
</div>