import { Component, Input } from '@angular/core';
import { HeroTernary } from 'src/app/interfaces/components.interface';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-hero-ternary',
	templateUrl: './hero-ternary.component.html',
	styleUrls: ['./hero-ternary.component.scss'],
})
export class HeroTernaryComponent {
	@Input() datas!: HeroTernary;
	imagesURL: string = environment.imagesURL;
}
