import { Component, Input, OnInit } from '@angular/core';
import { CollectionsService } from 'src/app/services/collections.service';
import { Social } from 'src/app/interfaces/collections.interface';

@Component({
	selector: 'app-event-useful',
	templateUrl: './event-useful.component.html',
	styleUrls: ['./event-useful.component.scss'],
})
export class EventUsefulComponent implements OnInit {
	@Input() event!: any;
	currentRoute: string = '';
	socials!: Social[];

	constructor(private collectionsService: CollectionsService) {}

	ngOnInit(): void {
		this.currentRoute = window.location.href;
		this.currentRoute = 'https://staging.floax.fr/nsi/evenement/b55822e0-8e47-49d8-a2bd-b9fca9e1da3a';
		this.getDatas();
	}

	async getDatas(): Promise<Social[]> {
		return this.socials = await this.collectionsService.getAllSocials();
	}
}
