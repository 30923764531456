<article class="introduce-nsi" *ngIf="datas">
	<section class="in-container">
		<div class="inc-part left">
			<div class="incp-title-wrapper">
				<img class="incptw-img" [src]="imagesUrl + datas.headIllus.path" alt="illustration semaine numérique et informatique" />
				<div class="incptw-title-subtitle">
					<h2 class="incptwts-title">{{ datas.firstParagraph.title }}</h2>
				</div>
			</div>
			<p class="incp-paragraph">{{ datas.firstParagraph.text }}</p>
		</div>
		<div class="inc-part right">
			<div class="incp-item">
				<img class="incpi-icon" [src]="imagesUrl + datas.firstIcon.path" alt="icone paragraphe" />
				<h3 class="incpi-title part-title">{{ datas.secondParagraph.title }}</h3>
				<p class="incpi-paragraph">{{ datas.secondParagraph.text }}</p>
			</div>
			<div class="incp-item">
				<img class="incpi-icon" [src]="imagesUrl + datas.secondIcon.path" alt="icone paragraphe" />
				<h3 class="incpi-title part-title">{{ datas.thirdParagraph.title }}</h3>
				<p class="incpi-paragraph">{{ datas.thirdParagraph.text }}</p>
			</div>
		</div>
	</section>
</article>
