import { Component, OnInit } from '@angular/core';
import {
	AboutDetails,
	HeroSecondary,
	IntroduceTrophees,
	ProjectPromoters,
	PartnersTypeData
} from 'src/app/interfaces/components.interface';
import { CollectionsService } from 'src/app/services/collections.service';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
	selector: 'app-about',
	templateUrl: './about.page.html',
	styleUrls: ['./about.page.scss'],
})
export class AboutPage implements OnInit {
	heroSecondary!: HeroSecondary;
	aboutDetails!: AboutDetails;
	introduceTrophees!: IntroduceTrophees;
	projectPromoters!: ProjectPromoters;
	financialPartners!: PartnersTypeData;
	institutionnalPartners!: PartnersTypeData;
	copilPartners!: PartnersTypeData;

	constructor(private componentsService: ComponentsService, private collectionsService: CollectionsService) {}

	ngOnInit(): void {
		this.getDatas();
		this.collectionsService.getAllPartners();
		this.collectionsService.getAllSocials();
		this.collectionsService.getAllPromoters();
		this.collectionsService.getAllPartnersByType('financial');
		this.collectionsService.getAllPartnersByType('institutionnal');
		this.collectionsService.getAllPartnersByType('copil');
	}

	async getDatas() {
		const response: any = await this.componentsService.getAllDatasOfSinglePage(
			'AboutPage',
			'error to fetch home datas',
		);
		const aboutPageDatas = response.aboutPage.length > 0 && response.aboutPage[0].children;
		if (aboutPageDatas) {
			this.heroSecondary = this.componentsService.formatHeroSecondary(aboutPageDatas);
			this.aboutDetails = this.componentsService.formatAboutDetails(aboutPageDatas);
			this.introduceTrophees = this.componentsService.formatIntroduceTrophees(aboutPageDatas);
			this.projectPromoters = this.componentsService.formatProjectPromoters(aboutPageDatas);
			this.financialPartners = this.componentsService.formatPartnersByType(aboutPageDatas, "financial");
			this.institutionnalPartners = this.componentsService.formatPartnersByType(aboutPageDatas, "institutionnal");
			this.copilPartners = this.componentsService.formatPartnersByType(aboutPageDatas, "copil");
		}
	}
}
