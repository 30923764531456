<section class="hero-ternary" *ngIf="datas" [ngStyle]="{ 'background-image': 'url(' + imagesURL + datas.image + ')' }">
	<div class="ht-container">
		<p class="htc-breadcrumb">Nos événements / Fiche événement</p>
		<h1 class="htc-title">{{ datas.title }}</h1>
		<div class="htc-buttons-container" *ngIf="datas.isButtonLinks">
			<custom-button
				*ngFor="let button of datas.buttons"
				routerLink="/{{ button.link }}"
				[color]="button.color"
				[level]="button.level"
				size="small"
				iconAfter="arrow-ios-forward-outline"
				>{{ button.text }}</custom-button
			>
		</div>
	</div>
</section>
