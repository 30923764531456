<main class="main" *ngIf="event && event.name">
	<section
		class="hero-secondary"
		[ngStyle]="{ 'background-image': event.event_img ? 'url(' + event.event_img + ')' : 'url(./assets/images/default-event.jpg)' }">
		<custom-button
			*ngIf="!inIframe() && getYear(event.start_date) < getYear(currentDate.toString())"
			class="hs-btn"
			color="neutral"
			level="secondary"
			size="small"
			iconBefore="arrow-ios-back-outline"
			routerLink="/evenements-2022"
			>Revenir aux événements 2022</custom-button>
		<custom-button
			*ngIf="!inIframe() && getYear(event.start_date) == getYear(currentDate.toString())"
			class="hs-btn"
			color="neutral"
			level="secondary"
			size="small"
			iconBefore="arrow-ios-back-outline"
			routerLink="/evenements"
			>Revenir aux événements</custom-button>
		<div class="container">
			<div class="container-col">
				<p class="hs-breadcrumb">Nos événements / {{ event.name }}</p>
				<h1 class="hs-title">{{ event.name }}</h1>
				<div class="hs-cta" *ngIf="event.registration_link">
					<!-- <a [href]="event.registration_link">
						<custom-button
							class="rcw-btn"
							color="accent"
							level="primary"
							size="small"
							iconAfter="arrow-ios-forward-outline"
							>S'inscrire à l'événement</custom-button
						>
					</a> -->
				</div>
			</div>
			<div class="container-col">
				<div class="event-date-wrapper">
					<div class="edw-day">{{ event.start_date | date: 'dd' }}</div>
					<div class="edw-month">{{ event.start_date | date: 'LLL YYYY' }}</div>
				</div>
			</div>
		</div>
	</section>
	<section class="description">
		<div class="event-tag">Pour qui :<span *ngFor="let public of event.public">{{ ' ' + public }}</span></div>
		<div class="d-content">
			<div class="previous-event" *ngIf="getYear(event.start_date) < getYear(currentDate.toString())">
				<i class="eva eva-alert-circle"></i>
				<p class="pe-text">Attention, l'événement suivant est terminé et fait partie d’une édition précédente</p>
			</div>
			<div class="edd-title-ctn">
				<h2 class="edd-title">{{ event.organization_name }}</h2>
				<p class="edd-subtitle">{{ event.type }}</p>
			</div>
			<!-- <app-section-title [title]="event.organization_name" [subtitle]="event.type"></app-section-title> -->
			<div class="dc-text-wrapper">
				<p class="dc-intro-title">À propos de l'événement :</p>
				<p class="dc-description" [innerHTML]="event.description"></p>
			</div>
		</div>
		<app-event-profile-card [event]="event"></app-event-profile-card>
	</section>
	<app-event-useful [event]="event"></app-event-useful>
	<section class="planning" *ngIf="event.eventDates && event.eventDates.length > 0">
		<div class="p-container">
			<app-section-title [title]="'Le programme'" [subtitle]="'de cet événement'"></app-section-title>
			<ul class="p-hours">
				<li class="ph-section" *ngFor="let eventDate of event.eventDates; let i = index">
					<app-event-schedule-tab [dateData]="eventDate" [number]="i"></app-event-schedule-tab>
				</li>
			</ul>
		</div>
	</section>
	<app-next-events [datas]="nextEventsDatas" [eventEndDate]="event.end_date" *ngIf="event.end_date && !inIframe()"></app-next-events>
</main>
