import { EventDate } from '../interfaces/collections.interface';

export const formatWeekDatesStartEnd = (startDate: EventDate, endDate: EventDate): string => {
	const tempStartDate: Date = new Date(`${startDate.date} ${startDate.time}`);
	const tempEndDate: Date = new Date(`${endDate.date} ${endDate.time}`);
	const isDatesSameMonth = tempStartDate.getMonth() === tempEndDate.getMonth() ? true : false;
	const formatedStartDate = isDatesSameMonth
		? new Intl.DateTimeFormat('fr-FR', { day: '2-digit' }).format(tempStartDate)
		: new Intl.DateTimeFormat('fr-FR', { month: 'long', day: '2-digit' }).format(tempStartDate);
	const formatedEndDate = new Intl.DateTimeFormat('fr-FR', { month: 'long', day: '2-digit' }).format(tempEndDate);

	return `${formatedStartDate} au ${formatedEndDate}`;
};
