<section class="sub-form classic-section">
	<div class="round-hat"></div>
	<div class="sf-container classic-container">
		<div class="sfc-text-wrapper">
			<p class="sfctw-text">{{ datas?.text }}</p>
			<div class="sfctw-title-wrapper">
				<span class="open-chevron chevron"></span>
				<h2 class="sfctwtw-title">{{ datas?.title }}</h2>
				<span class="close-chevron chevron"></span>
			</div>
		</div>
	</div>
</section>
